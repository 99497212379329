import React, { FunctionComponent, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  CartItemCardContainer,
  CartItemDetailsContainer,
  CartItemDetails,
  CartItemPrice,
  CartItemImage,
  CartText,
  BinBadge,
  Top,
  Bottom,
  DeleteContainer,
  CustomVariation,
  VariationContainer,
  CartItemTitle
} from './CartItemCard.style';
import Bin from '../../img/binIcon.png';
import showAmount from '../../utils/priceFormat.utils';
import { removeItemFromCart } from '../../store/actions/cartCountAction';
import { StatusView, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors, FontSizes } from '../../constants/theme';
import Counter from '../counter';
import { CheckBox } from '../OrderSummary/OrderSummary.style';
import { getItem } from '../../utils/localStorage.utils';
import { MdDeleteForever } from 'react-icons/md';
import { AppState } from '../../store/reducers';
import { addSelectFromCart } from '../../store/actions/addSelection';
import { InventoryPrice, InventoryPricingType } from '../../interfaces/inventory.interface';
import { getInventoryPrice } from '../../utils/inventory.utils';
import PriceFormatComponent from '../PriceFormatComponent';
interface Props {
  onCheckoutPage?: boolean;
  id: string;
  title: string;
  price: number;
  checked?: boolean;
  image: string;
  removeItemFromCart: (id: string) => void;
  getTotal?: Function;
  handleCheck?: () => void;
  inventoryType: string;
  merchant: string;
  quantity: number;
  variationName: string;
  variationId: string;
  inventoryPricing: InventoryPricingType;
  initialInventoryType: string;
}

const CartItemCard: FunctionComponent<Props> = ({
  removeItemFromCart,
  id,
  title,
  price,
  onCheckoutPage,
  image,
  getTotal,
  checked,
  handleCheck,
  inventoryType,
  merchant,
  quantity,
  variationName,
  variationId,
  inventoryPricing,
  initialInventoryType
}) => {
  const { primaryGrey, primaryColor } = Colors;
  const [priceInCart, setPrice] = useState(price);
  const savedSelectedItems = useSelector((state: AppState) => state.selectedCartItems.selectedCartItems);
  const dispatch = useDispatch();
  const removeFromCart = (id: string) => {
    removeItemFromCart(id);
    dispatch(addSelectFromCart(savedSelectedItems.filter((item) => item.id != id)));
  };

  const isPiecesAndPack = initialInventoryType === 'PIECES_AND_PACK';

  return (
    <CartItemCardContainer onCheckoutPage={onCheckoutPage}>
      <Top>
        {!onCheckoutPage && (
          <CheckBox htmlFor={id} checked={!!checked} onClick={handleCheck}>
            <span></span>
          </CheckBox>
        )}
        <input type="checkbox" hidden />
        <CartItemImage src={image} />
        <CartItemDetailsContainer>
          <CartItemDetails>
            <CartItemTitle>
              <div>
                <CartText productName fontSize={16}>
                  {title}
                </CartText>
                {/* <VariationContainer>
                  <CustomVariation>
                    <span>color:</span> black
                  </CustomVariation>
                  <CustomVariation style={{ marginLeft: "10px" }}>
                    <span>size:</span> large
                  </CustomVariation>
                </VariationContainer> */}
                <PriceFormatComponent customClass="pricing" inventoryPrice={inventoryPricing} />
              </div>
              <div style={{ alignSelf: 'flex-end' }}>
                {inventoryType === 'VARIATION' && (
                  <Text color={primaryColor}>
                    <StatusView>{variationName}</StatusView>
                  </Text>
                )}

                {initialInventoryType === 'PIECES_AND_PACK' && (
                  <Text color={primaryColor}>
                    <StatusView>{inventoryType.toLocaleLowerCase()}</StatusView>
                  </Text>
                )}
              </div>
            </CartItemTitle>

            <CartItemPrice>
              <DeleteContainer onClick={() => removeFromCart(id)}>
                <BinBadge onCart={true} onCheckoutPage={onCheckoutPage}>
                  {/* <img src={Bin} alt="binIcon" className="bin" /> */}
                  <MdDeleteForever className="bin" />
                </BinBadge>
                <p>Remove</p>
              </DeleteContainer>
              <Counter
                getTotal={getTotal}
                inventoryId={id}
                inventoryType={inventoryType}
                productName={title}
                imageurl={image}
                merchant={merchant}
                onCart={true}
                setFixedPrice={setPrice}
                fixedPrice={priceInCart}
                counterInitial={quantity}
                variationName={variationName}
                variationId={variationId}
              />
            </CartItemPrice>
          </CartItemDetails>
        </CartItemDetailsContainer>
      </Top>
    </CartItemCardContainer>
  );
};

export default connect(null, { removeItemFromCart })(CartItemCard);
