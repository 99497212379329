import { InventoryPricingType } from '../../interfaces/inventory.interface';
import { ADD_TO_CART_FROM_LOCALSTORAGE, ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from '../actionTypes';

export const addToCartFromLocalStorage = (cartData: any[]) => {
  return {
    type: ADD_TO_CART_FROM_LOCALSTORAGE,
    cartData: cartData
  };
};

export const addToCart = (
  id: string,
  title: string,
  price: number,
  quantity: number,
  fixedUnitPrice: number,
  merchant: string,
  image: string,
  inventoryType: string,
  variationId: string,
  variationName: string,
  inventoryPrice: InventoryPricingType | null,
  initialInventoryType?: string
) => {
  return {
    type: ADD_TO_CART,
    payload: {
      id,
      title,
      price,
      quantity,
      fixedUnitPrice,
      merchant,
      image,
      inventoryType,
      variationId,
      variationName,
      inventoryPrice,
      initialInventoryType
    }
  };
};

export const removeItemFromCart = (id: string) => {
  return {
    type: REMOVE_FROM_CART,
    id: id
  };
};

export const clearCart = (id: string) => {
  return {
    type: CLEAR_CART
  };
};
