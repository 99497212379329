import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  CheckoutInformation,
  CheckoutPageContentWrapper,
  Left,
  Right,
  CheckoutContainer,
  PaymentOptionContainer,
  DeliveryOptionContainer,
  DeliveryNav,
  DeliveryCard,
  DeliveryCardTop,
  Radio,
  DeliveryTitle,
  DeliveryCardBottom,
  IconContainer,
  CardDetails,
  SelectPaymentContainer,
  PaymentOptionCard,
  RadioPayment,
  PaymentSummary,
  ContinueButton,
  SmallText,
  DeliveryWarningContainer,
  ErrorModal
} from './checkout.style';
import OrderCompleted from '../../components/OrderCompleted';
import { Colors, FontSizes } from '../../constants/theme';
import { useQuery } from '../../utils/customRoute.utils';
import { getItem, setItem } from '../../utils/localStorage.utils';
import { BROAD_MIND_SHOP_NAME, pushCustomRouteQuery } from '../../utils/pushRoutes.utils';
import { hasfailed, isloading, resetFetch } from '../../store/actions/fetchAction';
import { connect, useDispatch, useSelector } from 'react-redux';
import { gql, useMutation, useQuery as apolloUseQuery } from '@apollo/client';
import { AppState } from '../../store/reducers';
import { nextCheckoutOption } from '../../store/actions/checkoutActions';
import { checkoutMethod } from '../../store/actions/userCheckoutInfo';
import { clearCart, removeItemFromCart } from '../../store/actions/cartCountAction';
import { getOrderItems } from '../../utils/order.utils';
import vanIcon from '../../img/van.svg';
import markIcon from '../../img/mark.svg';
import reviewIcon from '../../img/review.svg';
import lockIcon from '../../img/lock.svg';
import CartItemCard from '../../components/CartItemCard';
import { addSelectFromCart } from '../../store/actions/addSelection';
import { TotalContainer, TotalText } from '../../components/OrderSummary/OrderSummary.style';
import showAmount from '../../utils/priceFormat.utils';
import PopupCard from '../../components/PopupCard';
import ChangeAddress from '../../components/ChangeAddress';
import AddressForm from '../../components/AddressForm';
import DeliveryWarn from '../../img/delivery-warn.svg';
import ErrorWarn from '../../img/error-warn.svg';
import MapEllipse from '../../img/map-ellipse.svg';
import AskQuestion from '../../img/ask-question.svg';
import { Button, Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import Error from '../../components/ErrorComponent';
import { GET_DEFAULT_ADDRESS, GET_MEMBER_SCHEMA, SET_DEFAULT_ADDRESS } from '../../schema/member.schema';
import { GET_MEMBER_TYPE, LongAddressType } from '../../interfaces/GetMember.interface';
import Loader from '../../components/LoaderComponent';
import { AddressType, GET_DEFAULT_ADDRESS_TYPE } from '../../interfaces/address.interface';
import { CREATE_MEMBER_ORDER, GET_ORDER_FEE, GET_ORDER_FEE_TYPE, INITIATE_ORDER_PAYMENT } from '../../schema/checkout.schema';
import LocationEntry from '../../components/LocationEntry';
import { toggleSnackbarOpen } from '../../store/actions/snackbarActions';
import { OptionType } from '../../interfaces/FilterSelect.interface';
import { REQUEST_LOCATION_FEE } from '../../schema/messages.schema';
import { Dispatch } from 'redux';
import { showErrorMessage } from '../../utils/redux.utils';

const mapStateToProps = (state: AppState) => {
  return { checkoutMode: state.checkout.checkoutMode };
};

interface Props {
  nextCheckoutOption: Function;
  checkoutMethod: Function;
  clearCart: Function;
}
type PaymentMethodType = 'ON_DELIVERY' | 'ONLINE' | '';
type DeliveryType = 'DOORSTEP' | 'PICKUP' | 'EMAIL';

const CheckoutPageContent: FunctionComponent<Props> = ({ nextCheckoutOption, checkoutMethod, clearCart }) => {
  const { primaryGrey, primaryColor, white } = Colors;
  const { detailsFontSize, primaryFontSize, titleFont } = FontSizes;

  let authType = useQuery().get('type');
  const isSession = getItem('session');
  const history = useHistory();
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>('');
  const [deliveryOption, setDeliveryOption] = useState<DeliveryType>('DOORSTEP');
  const savedSelectedItems = useSelector((state: AppState) => state.selectedCartItems.selectedCartItems);
  const cartItems = useSelector((state: AppState) => state.CartCounter.cartItems);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [orderFees, setOrderFees] = useState<GET_ORDER_FEE_TYPE>();

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [showChangeAddress, setShowChangeAddress] = useState(false);
  const [showFormAddress, setShowFormAddress] = useState(false);
  const [isFullAddress, setIsFullAddress] = useState('');

  const [indexToEdit, setIndexToEdit] = useState(0);
  const [addressIdToEdit, setAddressIdToEdit] = useState('');
  const [defaultAddress, setDefaultAddress] = useState<AddressType>();

  const [isLoadingTotal, setIsLoadingTotal] = useState<boolean>(false);

  const [isDeliveryWarning, setDeliveryWarining] = useState<boolean>(false);
  const [warningMessages, setWarningMessages] = useState<string[]>();
  const [errorShop, setErrorShop] = useState<string[]>();

  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [gqlError, setGqlError] = useState<string | undefined>();
  const [feeError, setFeeError] = useState<string | undefined>();

  const [referenceId, setReferenceId] = useState<string>();
  const [isRequestPopupCardOpened, setRequestPopupCardOpened] = useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<OptionType>();
  const [selectedCity, setSelectedCity] = useState<OptionType>();

  const { data, loading, error, refetch } = apolloUseQuery<GET_MEMBER_TYPE>(GET_MEMBER_SCHEMA, {
    fetchPolicy: 'no-cache'
  });

  const { data: addressData } = apolloUseQuery<GET_DEFAULT_ADDRESS_TYPE>(GET_DEFAULT_ADDRESS, {
    fetchPolicy: 'no-cache'
  });

  const selectedAddress = data?.getMember?.Addresses?.[selectedAddressIndex];
  const { addressId, fullAddress } = defaultAddress || {};
  const member = JSON.parse(getItem('session') || '{}').member;

  useEffect(() => {
    if (addressData?.getDefaultAddress) {
      setDefaultAddress(addressData.getDefaultAddress);
    }
  }, [addressData, setDefaultAddress]);

  // useEffect(() => {
  //   const redirectToLogin = () => {
  //     if (!isSession) {
  //       history.push('/login?redirect=/checkout');
  //     }
  //   };
  //   redirectToLogin();
  // });

  useEffect(() => {
    const city = defaultAddress?.City?.cityId;
    const state = defaultAddress?.State?.stateId;
    const streetAddress = defaultAddress?.streetAddress;
    if (city && state && streetAddress) {
      setIsFullAddress('COMPLETE');
    } else if (city && state && !streetAddress) {
      setIsFullAddress('INCOMPLETE');
    } else {
      setIsFullAddress('NO_ADDRESS');
    }
  }, [defaultAddress]);

  const [getOrderFee] = useMutation<{ getOrderFees: GET_ORDER_FEE_TYPE }>(GET_ORDER_FEE, {
    onCompleted({ getOrderFees }) {
      setOrderFees(getOrderFees);
      const { deliveryFee, transactionFee, subTotal, errorMessages, warningMessages, errorAndWarningMessages } = getOrderFees;
      setDeliveryFee(deliveryFee);
      setTransactionFee(transactionFee);
      setErrorMessages(errorMessages);
      setWarningMessages(warningMessages);
      setErrorShop(errorAndWarningMessages.filter((error) => error.errorMessages.length > 0).map(({ shopId }) => shopId));
      if (authType === 'checkout' && warningMessages.length > 0) {
        setDeliveryWarining(true);
      }
      setSubTotal(subTotal);
      setIsLoadingTotal(false);
      dispatch(resetFetch());
    },
    onError: (e) => setFeeError(e.message)
  });

  const [initiazeOrderPayment] = useMutation<any>(INITIATE_ORDER_PAYMENT, {
    onCompleted({ initiateOrderPayment }) {
      const options: any = {
        key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
        email: data?.getMember.email,
        amount: getAmount(),
        ref: initiateOrderPayment.referenceId
      };

      ((window as any)['PaystackPop'] as any)
        .setup({
          ...options,
          onClose: () => {
            pushCustomRouteQuery('cart', '', '');
            alert('Transaction Cancelled');
          },
          callback: async (response: any) => {
            await createOrder({
              referenceId: initiateOrderPayment.referenceId
            });
            pushCustomRouteQuery('checkout', 'type', 'completed');
          }
        })
        .openIframe();
    },
    onError: (e) => setGqlError('Could not create Order')
  });

  const [createMemberOrder] = useMutation<any>(CREATE_MEMBER_ORDER, {
    onCompleted() {
      dispatch(resetFetch());
      pushCustomRouteQuery('checkout', 'type', 'completed');
      savedSelectedItems.forEach((item) => {
        dispatch(removeItemFromCart(item.id));
      });
    },
    onError: (e) => setGqlError('Could not create Order')
  });

  const createOrder = async (data: any = {}) => {
    if (!defaultAddress) {
      hasfailed('Please select Address');
      return;
    }

    try {
      dispatch(isloading());
      await createMemberOrder({
        variables: {
          orderItems: getOrderItems(savedSelectedItems),
          paymentMethod,
          deliveryOption,
          addressId: defaultAddress.addressId,
          ...data
        }
      });
      dispatch(checkoutMethod(data));
    } catch (e) {
      showErrorMessage(dispatch, error);
    }
  };

  const initiateOrder = async () => {
    try {
      dispatch(isloading());
      await initiazeOrderPayment({
        variables: {
          orderItems: getOrderItems(savedSelectedItems),
          deliveryOption,
          addressId: addressId
        }
      });
    } catch (e) {
      showErrorMessage(dispatch, e);
    }
  };

  const getTotal = () => {
    const totalOfCartPrice = () => {
      let initialTotal = 0;
      savedSelectedItems.reduce((amount: number, item: any) => {
        return (initialTotal = item.price + amount);
      }, 0);
      return initialTotal;
    };
    const total = totalOfCartPrice();

    if (paymentMethod && deliveryOption) {
      setIsLoadingTotal(true);
      try {
        getOrderFee({
          variables: {
            orderItems: getOrderItems(savedSelectedItems),
            paymentMethod: paymentMethod,
            deliveryOption: deliveryOption,
            addressId: addressId
          }
        });
      } catch (error) {
        setGqlError('Something went wrong');
      }
    } else {
      setSubTotal(total);
      setItem('totalCartAmount', total);
    }
  };

  useEffect(() => {
    if (savedSelectedItems.length > 0) {
      const savedItemsId = savedSelectedItems.map((item) => item.id);
      const updatedSavedCartItems = cartItems.filter((item) => savedItemsId.includes(item.id));
      if (updatedSavedCartItems.length > 0) {
        dispatch(addSelectFromCart(updatedSavedCartItems));
      }
    }
  }, [cartItems]);

  useEffect(() => {
    getTotal();
  }, [deliveryOption, paymentMethod, savedSelectedItems]);

  const getAmount = () => {
    const total = (orderFees?.totalAmount! * 100).toFixed();
    return total;
  };

  useEffect(() => {
    savedSelectedItems.map((item) => {
      const { merchant: shopName } = item;
      if (shopName === BROAD_MIND_SHOP_NAME) {
        setPaymentMethod('ONLINE');
        setDeliveryOption('EMAIL');
      }
    });
  }, []);

  const handleProceed = async () => {
    try {
      if (paymentMethod === 'ONLINE') {
        if (!defaultAddress) {
          hasfailed('Please select Address');
          return;
        }
        await initiateOrder();
      } else {
        await createOrder();
      }
    } catch (e) {
      showErrorMessage(dispatch, e);
    }
  };

  const closeErrorModal = () => {
    setErrorMessages([]);
    setPaymentMethod('');
  };

  const [requestLocationShippingFee] = useMutation<any>(REQUEST_LOCATION_FEE, {
    onCompleted() {
      setRequestPopupCardOpened(false);
      dispatch(toggleSnackbarOpen('Request Sent To Merchant'));
    }
  });

  const requestDelivery = async () => {
    try {
      if (!selectedState || !selectedCity) {
        dispatch(toggleSnackbarOpen('Please select a state and city'));
        return;
      }

      for (const shop of errorShop ?? []) {
        await requestLocationShippingFee({
          variables: {
            shopId: shop,
            stateId: String(selectedState?.value),
            cityId: String(selectedCity?.value)
          }
        });
      }
      setRequestPopupCardOpened(false);
      closeErrorModal();
    } catch (e) {
      showErrorMessage(dispatch, e);
    }
  };

  return (
    <CheckoutPageContentWrapper>
      <h3>Checkout</h3>
      {savedSelectedItems.length == 0 && <Redirect to="/cart" />}

      {!authType && <Redirect to={'/checkout?type=checkout'} />}
      {authType === 'checkout' && (
        <CheckoutContainer>
          <Left>
            <DeliveryOptionContainer>
              <DeliveryNav>
                <img src={vanIcon} />
                <p>Delivery/Pick up Options</p>
              </DeliveryNav>
              {deliveryOption === 'EMAIL' ? (
                <DeliveryCard selected={deliveryOption === 'EMAIL'} onClick={() => setDeliveryOption('EMAIL')}>
                  <DeliveryCardTop>
                    <div style={{ display: 'flex' }}>
                      <Radio selected={deliveryOption === 'EMAIL'}>
                        <span></span>
                      </Radio>
                      <DeliveryTitle>Digital</DeliveryTitle>
                    </div>
                  </DeliveryCardTop>
                  <DeliveryCardBottom>
                    <IconContainer></IconContainer>
                    <CardDetails>
                      <p>Digital</p>
                    </CardDetails>
                  </DeliveryCardBottom>
                </DeliveryCard>
              ) : (
                <>
                  <DeliveryCard selected={deliveryOption === 'DOORSTEP'} onClick={() => setDeliveryOption('DOORSTEP')}>
                    <DeliveryCardTop>
                      <div style={{ display: 'flex' }}>
                        <Radio selected={deliveryOption === 'DOORSTEP'}>
                          <span></span>
                        </Radio>
                        <DeliveryTitle>Door Step Delivery</DeliveryTitle>
                      </div>
                      {fullAddress && (
                        <button id="change-address-btn" onClick={() => setShowChangeAddress(true)}>
                          Change or Update Address
                        </button>
                      )}
                    </DeliveryCardTop>
                    {loading ? (
                      <Loader />
                    ) : (
                      <DeliveryCardBottom>
                        {isFullAddress === 'COMPLETE' && (
                          <IconContainer>
                            <img src={markIcon} alt="marker" />
                          </IconContainer>
                        )}
                        {isFullAddress === 'INCOMPLETE' ? (
                          <CardDetails style={{ marginLeft: '3rem' }}>
                            You have an incomplete address,{' '}
                            <span style={{ color: `${primaryColor}` }} onClick={() => setShowChangeAddress(true)}>
                              Update or add a new address
                            </span>
                          </CardDetails>
                        ) : (
                          <CardDetails>
                            {isFullAddress === 'NO_ADDRESS' ? (
                              <AddressForm
                                setShow={setShowFormAddress}
                                user={data?.getMember}
                                indexToEdit={indexToEdit}
                                addressIdToEdit={addressIdToEdit}
                                refetch={refetch}
                                isCheckout={true}
                                setDefaultAddress={setDefaultAddress}
                              />
                            ) : (
                              <>
                                <h3>{defaultAddress?.fullName}</h3>
                                <p>{defaultAddress?.mobileNumber}</p>
                                <p>{defaultAddress?.fullAddress} </p>
                              </>
                            )}
                          </CardDetails>
                        )}
                      </DeliveryCardBottom>
                    )}
                  </DeliveryCard>
                  <DeliveryCard selected={deliveryOption === 'PICKUP'} onClick={() => setDeliveryOption('PICKUP')}>
                    <DeliveryCardTop>
                      <div style={{ display: 'flex' }}>
                        <Radio selected={deliveryOption === 'PICKUP'}>
                          <span></span>
                        </Radio>
                        <DeliveryTitle>
                          Pickup from Store <span>(Pickup order at your own convenience from sellers location)</span>
                        </DeliveryTitle>
                      </div>
                    </DeliveryCardTop>
                    <DeliveryCardBottom>
                      <IconContainer></IconContainer>
                      <CardDetails>
                        <p>Select this option to pick your order from the store (No delivery charges incurred)</p>
                      </CardDetails>
                    </DeliveryCardBottom>
                  </DeliveryCard>
                </>
              )}
            </DeliveryOptionContainer>
            <DeliveryOptionContainer style={{ marginTop: '20px' }}>
              <DeliveryNav>
                <img src={reviewIcon} />
                <p>Review Order</p>
              </DeliveryNav>
              {savedSelectedItems.map((item) => {
                const {
                  id,
                  image,
                  inventoryType,
                  merchant,
                  price,
                  quantity,
                  title,
                  variationName,
                  variationId,
                  inventoryPrice,
                  initialInventoryType
                } = item;
                return (
                  <CartItemCard
                    key={id}
                    id={id}
                    title={title}
                    price={price}
                    onCheckoutPage={true}
                    image={image}
                    getTotal={getTotal}
                    inventoryType={inventoryType}
                    merchant={merchant}
                    quantity={quantity}
                    variationName={variationName}
                    variationId={variationId}
                    inventoryPricing={inventoryPrice}
                    initialInventoryType={initialInventoryType || inventoryType}
                  />
                );
              })}
            </DeliveryOptionContainer>
          </Left>
          <Right>
            <PaymentOptionContainer>
              <h2>Payment Options</h2>
              <SelectPaymentContainer>
                <PaymentOptionCard selected={paymentMethod === 'ONLINE'}>
                  <RadioPayment selected={paymentMethod === 'ONLINE'} onClick={() => setPaymentMethod('ONLINE')}>
                    <p>Pay Now</p>
                    <span>
                      <span className="inner-span"></span>
                    </span>
                  </RadioPayment>
                </PaymentOptionCard>
                {deliveryOption !== 'EMAIL' ? (
                  <PaymentOptionCard selected={paymentMethod === 'ON_DELIVERY'}>
                    <RadioPayment selected={paymentMethod === 'ON_DELIVERY'} onClick={() => setPaymentMethod('ON_DELIVERY')}>
                      <p>Pay on Delivery</p>
                      <span>
                        <span className="inner-span"></span>
                      </span>
                    </RadioPayment>
                  </PaymentOptionCard>
                ) : null}
              </SelectPaymentContainer>
              <PaymentSummary>
                <TotalContainer>
                  <TotalText>Sub Total</TotalText>
                  <TotalText>{showAmount(subTotal)}</TotalText>
                </TotalContainer>
                {deliveryOption === 'PICKUP' && (paymentMethod === 'ON_DELIVERY' || paymentMethod === 'ONLINE') ? null : (
                  <>
                    {deliveryFee > 0 && (
                      <TotalContainer>
                        <TotalText>Delivery Fee</TotalText>
                        {deliveryFee > 0 && <TotalText>{showAmount(deliveryFee)}</TotalText>}
                      </TotalContainer>
                    )}
                  </>
                )}
                {paymentMethod === 'ON_DELIVERY' ? null : (
                  <>
                    {transactionFee > 0 && (
                      <TotalContainer>
                        <TotalText>Processing Fee</TotalText>
                        <TotalText>{showAmount(transactionFee)}</TotalText>
                      </TotalContainer>
                    )}
                  </>
                )}
                {deliveryOption === 'PICKUP' && (paymentMethod === 'ON_DELIVERY' || paymentMethod === 'ONLINE') ? (
                  <TotalContainer style={{ margin: '25px 0 15px 0', borderTop: '1px solid #E4EBF4', padding: '15px 0' }}>
                    <TotalText label>Total</TotalText>
                    <TotalText label fontSize="25px">
                      {showAmount(subTotal + transactionFee)}
                    </TotalText>
                  </TotalContainer>
                ) : (
                  <TotalContainer style={{ margin: '25px 0 15px 0', borderTop: '1px solid #E4EBF4', padding: '15px 0' }}>
                    <TotalText label>Total</TotalText>
                    <TotalText label fontSize="25px">
                      {showAmount(subTotal + deliveryFee + transactionFee)}
                    </TotalText>
                  </TotalContainer>
                )}
                {isFullAddress !== 'COMPLETE' &&
                deliveryOption === 'DOORSTEP' &&
                (paymentMethod === 'ONLINE' || paymentMethod === 'ON_DELIVERY') ? (
                  <p style={{ fontWeight: 'bolder', color: 'red', fontSize: '13px' }}>
                    You can't proceed to payment,{' '}
                    <strong style={{ color: 'black', textTransform: 'lowercase' }}>{feeError ?? 'User address is incomplete'}</strong>.
                  </p>
                ) : (
                  <ContinueButton
                    disabled={isLoadingTotal || (errorMessages?.length ?? 0) > 0}
                    style={{
                      opacity: `${!paymentMethod || (errorMessages?.length ?? 0) > 0 ? '0.5' : '1'}`,
                      cursor: `${!paymentMethod || (errorMessages?.length ?? 0) > 0 ? 'not-allowed' : 'pointer'}`
                    }}
                    onClick={handleProceed}
                  >
                    {paymentMethod === 'ON_DELIVERY' ? 'Checkout' : 'Continue to Payment'}
                  </ContinueButton>
                )}
                <SmallText>
                  <img src={lockIcon} alt="" />
                  Transactions are 100% Safe and Secure
                </SmallText>
              </PaymentSummary>
            </PaymentOptionContainer>
          </Right>
        </CheckoutContainer>
      )}

      <CheckoutInformation>
        {/* <Slider>
          <RadioPoints>
            <RadioCircle color={primaryColor} className="im im-radio-button-circle-o" />
            <RadioCircle
              color={authType === 'payment' || authType === 'completed' ? primaryColor : lightBlue}
              className="im im-radio-button-circle-o"
            />
            <RadioCircle color={authType === 'completed' ? primaryColor : lightBlue} className="im im-radio-button-circle-o" />
          </RadioPoints>
          <Line />
        </Slider> */}
        {/* {authType === 'address' && <ShippingAddress />} */}
        {/* {authType === 'payment' && (
          <PaymentOption
            deliveryOption={deliveryOption}
            paymentMethod={paymentMethod}
            setDeliveryOption={setDeliveryOption}
            setPaymentMethod={setPaymentMethod}
          />
        )} */}
        {authType === 'completed' && <OrderCompleted paymentMethod={paymentMethod} deliveryOption={deliveryOption} />}
      </CheckoutInformation>

      {showChangeAddress && (
        <PopupCard close={() => setShowChangeAddress(false)}>
          <ChangeAddress
            user={data?.getMember}
            selectedAddressIndex={selectedAddressIndex}
            setSelectedAddressIndex={setSelectedAddressIndex}
            setShow={setShowChangeAddress}
            setShowAddAddress={setShowFormAddress}
            setIndexToEdit={setIndexToEdit}
            setAddressIdToEdit={setAddressIdToEdit}
            refetch={refetch}
            setDefaultAddress={setDefaultAddress}
            defaultAddress={defaultAddress}
          />
        </PopupCard>
      )}

      {showFormAddress && (
        <PopupCard close={() => setShowFormAddress(false)}>
          <AddressForm
            setShow={setShowFormAddress}
            user={data?.getMember}
            indexToEdit={indexToEdit}
            addressIdToEdit={addressIdToEdit}
            refetch={refetch}
            setDefaultAddress={setDefaultAddress}
          />
        </PopupCard>
      )}
      {isDeliveryWarning && (
        <PopupCard maxWidth="500px" close={() => setDeliveryWarining(false)}>
          <DeliveryWarningContainer direction="column">
            <img src={DeliveryWarn} />
            <Text color={primaryGrey} fontSize={primaryFontSize}>
              Caution
            </Text>
            {warningMessages?.map((warning) => (
              <Text color={primaryGrey} fontSize={detailsFontSize}>
                {warning}
              </Text>
            ))}
            <Flex width="100%" justifyContent="space-evenly">
              <Button
                width="40%"
                borderRadius="8px"
                notFilled
                borderSize="2px"
                color={primaryColor}
                onClick={() => setDeliveryWarining(false)}
              >
                Continue
              </Button>
              <Button
                width="40%"
                borderRadius="8px"
                onClick={() => {
                  setPaymentMethod('ONLINE');
                  setDeliveryWarining(false);
                }}
              >
                Use Pay Now
              </Button>
            </Flex>
          </DeliveryWarningContainer>
        </PopupCard>
      )}
      {(errorMessages?.length ?? 0) > 0 && (
        <PopupCard maxWidth="500px" close={closeErrorModal}>
          <DeliveryWarningContainer direction="column">
            <img src={ErrorWarn} />
            <Text color={primaryGrey} fontSize={titleFont} fontWeight="600">
              Attention
            </Text>
            {errorMessages?.map((error) => (
              <Text
                dangerouslySetInnerHTML={{ __html: error.replace(/"(.*?)"/g, '<strong>$1</strong>') }}
                textAlign="center"
                color={primaryGrey}
                fontSize={detailsFontSize}
              />
            ))}
            <Button className="hover-btn" width="80%" borderRadius="8px" borderSize="2px" onClick={() => setDeliveryOption('PICKUP')}>
              Pick Item from Store
            </Button>
            <Flex className="hover-txt" gap="0.5rem" onClick={() => setRequestPopupCardOpened(true)} cursor="pointer">
              <img style={{ margin: '0' }} src={AskQuestion} />
              <u>
                <Text color={primaryColor} fontSize={primaryFontSize} textDecoration="underline">
                  Request delivery to your location
                </Text>
              </u>
            </Flex>
          </DeliveryWarningContainer>
          {/* <ErrorModal>
            <img src={MapEllipse} />
            
          </ErrorModal>        */}
        </PopupCard>
      )}
      {isRequestPopupCardOpened && (
        <PopupCard close={() => setRequestPopupCardOpened(false)}>
          <Flex direction="column" padding="2rem 0">
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <LocationEntry
                flexDirection="column"
                padding="10px 0"
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
              />
            </Flex>
            <Button width="100%" type="button" borderRadius="6px" margin="25px 0 0 0" onClick={requestDelivery}>
              Request Delivery
            </Button>
          </Flex>
        </PopupCard>
      )}
      {gqlError && (
        <PopupCard maxWidth="500px" close={() => setGqlError(undefined)}>
          <Error message={gqlError} />
        </PopupCard>
      )}
    </CheckoutPageContentWrapper>
  );
};

export default connect(mapStateToProps, { nextCheckoutOption, checkoutMethod, clearCart })(CheckoutPageContent);
