const showAmount = (amount: number, currency?: string): string | null => {
  if (amount === undefined || amount === null) {
    return '--';
  }
  // if (amount === 0) {
  //   return '--';
  // }
  const formattedAmount = amount?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return `${currency ?? '₦ '}${formattedAmount}`;
};

export default showAmount;
