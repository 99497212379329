import styled from 'styled-components';
import { Colors, FontSizes, media } from '../../constants/theme';

const { primaryColor, inActiveTab, white, primaryGrey, grey4, inputBorderColor, placeholderColor } = Colors;
const { primaryFontSize } = FontSizes;
const { smallScreenLaptop, mobile, tablet } = media;

interface ActiveProps {
  active?: boolean;
  margin?: string;
}
interface PageProps {
  page?: number;
  direction?: string;
  marginTop?: string;
  width?: string;
}

interface DeliveryCardProps {
  selected: boolean;
}

export const AccountPageTop = styled.div`
  padding: 1rem 2rem;
  max-width: 1440px;
  margin: auto;

  .sidebar-button {
    display: none;
  }

  ${smallScreenLaptop} {
    padding: 1rem 2rem;
  }

  ${tablet} {
    margin-top: 0px;
  }

  ${mobile} {
    padding: 1rem;
    padding-top: 3.5rem;
    margin-top: unset;
    align-self: start;

    .sidebar-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 3.65rem;
      right: 17px;
    }
  }
`;

interface TextProps {
  color?: string;
  fontWeight?: string;
  fontSize?: string;
  link?: boolean;
  margin?: string;
  subtitle?: boolean;
  status?: boolean;
  background?: string;
  padding?: string;
  textAlign?: string;
  mobile?: boolean;
}

export const Title = styled.h4<TextProps>`
  color: ${primaryGrey};
  font-size: ${({ subtitle }) => (subtitle ? primaryFontSize : '20px')};
  font-weight: 700;
  margin: ${({ margin }) => margin};
  padding: ${({ subtitle }) => (subtitle ? '15px 25px ' : '0px')};
  margin-bottom: ${({ subtitle }) => (subtitle ? '0' : '20px')};
  margin-top: 0px;

  ${mobile} {
    padding: 10px;
  }
`;

export const Text = styled.p<TextProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : primaryFontSize)};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => (margin ? margin : '6px')};
  text-transform: capitalize;
  background: ${({ background }) => background};
  padding: ${({ padding }) => padding};
  text-align: ${({ textAlign }) => textAlign};

  ${({ link }) =>
    link &&
    `
    cursor: pointer;
  `}

  ${({ status }) =>
    status &&
    `
    border-radius: 5px;
  `}

  ${mobile} {
    font-size: 12px;
    margin: 2px 2px 2px 0px;

    ${({ mobile }) =>
      mobile &&
      `
    font-size: 10px;
    padding: 0;
 
  `}
    ${({ status }) =>
      status &&
      `
    font-size: 8px;
    padding: 1px 5px;
  `}
  }
`;

export const AccountPageSidebar = styled.div`
  max-width: 235px;
  width: 100%;
  padding: 15px;
  min-height: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 4px 4px 30px rgba(35, 54, 79, 0.1);
  border-radius: 10px;

  ${smallScreenLaptop} {
    width: 22%;
  }
  ${tablet} {
    width: 100%;
  }

  ${mobile} {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    padding-left: 20px;
    margin: auto;
    z-index: 20;
  }
`;

export const NavContainer = styled.div<PageProps>`
  display: flex;
  margin: 3% 0;
  margin-top: ${({ marginTop }) => marginTop};
`;

export const NavigationLink = styled.p<ActiveProps>`
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : '16px 0px')};
  color: ${({ active }) => (active ? primaryColor : primaryGrey)};
  font-size: 10px;

  &:hover {
    color: ${primaryColor};
  }
`;

export const NavImage = styled.div``;

export const NavHeaderText = styled.p`
  color: ${primaryGrey};
  font-size: 1rem;
  font-weight: bolder;
  margin: 0;
`;

export const NavigationLinkContainer = styled.div`
  margin-left: 10%;
`;

export const PageContent = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  .empty-text {
    display: flex;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .show {
    display: flex !important;
  }

  ${smallScreenLaptop} {
    flex-direction: row;
  }
  ${tablet} {
    flex-direction: row;
  }
`;

export const DisplayCard = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 30px rgba(35, 54, 79, 0.1);
  border-radius: 10px;
  width: 100%;
  margin-left: 3%;
  min-height: 300px;

  ${smallScreenLaptop} {
    margin-left: 0;
    max-width: 70%;
  }

  ${tablet} {
    margin-left: 0px;
    max-width: 70%;
  }

  ${mobile} {
    max-width: 433px;
    margin-top: 0px;
    width: 100%;
    height: 100%;
    min-width: unset;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;
export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
`;

export const Hr = styled.hr`
  margin: 0px;
  display: block;
  padding: 0;
  width: 100%;
  border-top: 1px solid #8196b32f;
  border-bottom: none;
`;
export const MainAccountContent = styled.div`
  width: 100%;

  ${smallScreenLaptop} {
    width: 100%;
    margin-top: 3rem;
  }

  ${mobile} {
    margin-top: 0;
  }
`;

export const OrderHistoryTitle = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabSwitch = styled.div`
  display: flex;
  justify-content: start;
  padding: 0px 20px;
  position: absolute;
  bottom: -2px;

  ${mobile} {
    justify-content: space-between !important;
    padding: 0px;
    margin: 0px 10px;
    width: auto;
  }
`;

export const TabContainer = styled.div<ActiveProps>`
  position: relative;
  margin-top: 20px;

  ${mobile} {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
  }
`;
export const TabOption = styled.button<ActiveProps>`
  text-align: center;
  border: none;
  border-bottom: 2px solid ${({ active }) => (active ? primaryColor : 'transparent')};
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  color: ${({ active }) => (active ? primaryColor : grey4)};
  background: none;
  margin-right: 2rem;

  &:hover {
    color: ${primaryColor};
  }

  ${mobile} {
    margin-right: 10px;
    padding: 0px 2px;
    font-size: 14px;
  }
`;

export const OrderCardContainer = styled.div`
  min-height: 86px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem 0;
  border-radius: 4px;
  border: 1px solid #f3f3f3;
  cursor: pointer;

  .shopName {
    &:hover {
      color: ${primaryColor};
    }
  }

  .lower-card-content__row1 {
    width: 65%;
  }

  .lower-card-content__row2 {
    display: flex;
    align-items: center;
    width: 25%;
  }

  ${smallScreenLaptop} {
    .lower-card-content__row2 {
      width: 20%;
    }
  }

  ${mobile} {
    flex-direction: column;
    width: unset;

    .lower-card-content {
      flex-direction: column;
      align-items: baseline;

      .lower-card-content__row1,
      .lower-card-content__row2 {
        width: 100%;
      }

      .lower-card-content__row1 {
        flex-direction: column;
        align-items: baseline;

        .lower-card-content__row1__items {
          width: 100% !important;
          justify-content: space-between;
        }
      }
    }
  }
`;

export const Orderimage = styled.img`
  width: 15%;
  height: 100%;
`;

export const OrderCardContent = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;

  ${mobile} {
    width: 100%;
  }
`;

interface FlexProps {
  justifyContent?: string;
  alignItems?: string;
  flexFlow?: string;
  width?: string;
  mobileDirection?: string;
  grid?: boolean;
  padding?: string;
  background?: string;
  fontWeight?: string;
  border?: boolean;
  margin?: string;
  subtitle?: boolean;
  borderRadius?: string;
  direction?: string;
  smWidth?: string;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  border-radius: ${({ grid }) => (grid ? '10px' : 'none')};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-flow: ${({ flexFlow }) => flexFlow};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  background-color: ${({ background }) => (background ? background : 'none')};
  flex-direction: ${({ direction }) => direction};

  ${mobile} {
    flex-direction: ${({ mobileDirection }) => mobileDirection};
    padding: 0;
    background: none;
    border-radius: none;
    width: ${({ smWidth }) => (smWidth ? smWidth : '100%')};
  }
`;

interface InputBoxProps {
  padding?: string;
}

export const MobileOnly = styled.div`
  display: none;

  ${mobile} {
    display: flex;
  }
`;
export const LargeScreenOnly = styled.div`
  display: flex;

  ${mobile} {
    display: none;
  }
`;

export const DetailBox = styled.div<FlexProps>`
  display: flex;
  color: #607087;
  font-weight: ${({ fontWeight }) => fontWeight};
  padding: ${({ padding }) => padding};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-flow: ${({ flexFlow }) => flexFlow};
  width: ${({ width }) => (width ? width : 'auto')};
  background: ${({ background }) => background};
  margin: ${({ margin }) => margin};
  border: ${({ border }) => (border ? '1px solid #e4ebf4' : 'none')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 'none')};

  ${mobile} {
    width: auto;
    padding: ${({ subtitle }) => (subtitle ? '3px' : '0px')};
  }
`;

export const InputBox = styled.div<InputBoxProps>`
  padding: ${({ padding }) => padding};
  border: 1px solid ${inputBorderColor};
  margin-bottom: 0.5rem;
  border-radius: 5px;
  height: 50px;
  min-width: 49%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &:focus-within {
    border: 1px solid ${primaryColor};
  }

  input:focus,
  input:focus-visible,
  input:disabled {
    background: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
  }

  span {
    position: absolute;
    top: 12px;
    right: 10px;
    align-items: center;
    display: flex;
    color: ${inputBorderColor};
    cursor: pointer;
  }
`;

interface LabelProps {
  margin?: string;
  fontSize?: string;
}

export const Label = styled.label<LabelProps>`
  color: ${primaryGrey};
  font-size: ${({ fontSize }) => fontSize || '0.75rem'};
  margin: ${({ margin }) => margin};
  text-align: left;
`;

export const Input = styled.input`
  border-radius: 5px;
  height: 100%;
  width: 80%;
  padding: 0 0.6em;
  border: none;
  outline: transparent;
  background: none;
  font-size: ${primaryFontSize};

  &::placeholder {
    color: ${placeholderColor};
    font-size: 0.75rem;
  }
`;

export const FormContainer = styled.form`
  margin: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .outerDiv {
    display: flex;
    justify-content: space-between;
    ${mobile} {
      flex-direction: column;
    }
  }

  .p {
    color: #b9b9b9;
  }

  .innerDiv {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    ${tablet} {
      margin-left: 10px;
      margin-right: 10px;
    }

    ${mobile} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  ${mobile} {
    margin: 5%;
    height: auto;
  }
`;

export const OrderDetailWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: auto;
  background: ${white};
  padding-inline: 5px;
  width: 100%;

  .title {
    display: flex;
    padding: 0px;
    margin: 0px;
    align-items: center;
    justify-content: space-between;
    width: auto;
  }

  ${mobile} {
    margin: 2rem 0.5rem;
    width: 100%;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: transparent;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  .closeButton {
    color: #e4ebf4;
    font-size: 25px;
  }

  &:hover {
    background-color: #e4ebf4;

    .closeButton {
      transition: all ease-in-out 0.3s;
      color: ${white};
    }
  }
`;

export const AccSettingRWrapper = styled.div<PageProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  width: ${({ width }) => width};
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 4%;

  ${mobile} {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-right: 0;
  }
`;
export const AccSettingLWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  padding: 0 1%;

  ${mobile} {
    width: 80%;
    padding-left: 0;
  }
`;

export const OrderContainer = styled.div`
  padding: 0px 25px;

  ${mobile} {
    padding: 0px 10px;
  }
`;

export const ImgContainer = styled.div<PageProps>`
  width: ${({ width }) => (width ? width : '10%')};
  margin-right: 10px;

  img {
    width: 100%;
  }
  ${mobile} {
    width: 25%;
    margin-right: 5px;
  }
`;

export const ViewButton = styled.button`
  display: flex;
  color: ${primaryColor};
  border: none;
  background: none;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: ease-in-out all 0.3s;

  ${mobile} {
    width: 50%;
    justify-content: center;
  }

  .icon {
    margin-left: 10px;
  }

  &:hover {
    color: #ffe499;
  }
`;

export const Page = styled.div<PageProps>`
  width: 100%;
  display: flex;
  margin: auto;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 1px;

  .prev {
    height: 30px;
    padding: 0 20px;
    border: none;
    background-color: ${primaryColor};
    color: #fff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    cursor: pointer;

    &:disabled {
      color: #cccccc;
      cursor: unset;
      background-color: ${inActiveTab};
    }
  }
  .next {
    height: 30px;
    padding: 0 20px;
    background-color: ${primaryColor};
    border: none;
    color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer;

    &:disabled {
      color: #cccccc;
      cursor: unset;
      background-color: ${inActiveTab};
    }
  }
`;

// export const AddressCardDetails = styled.div`
//   display: flex;
//   flex-direction: column;
//   h3,
//   p {
//     padding: 2% 0;
//   }
//   margin-bottom: 2%;
// `;

export const Control = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`;

export const ContainerAddress = styled.div``;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NewAddressButton = styled.button`
  padding: 3px 10px;
  color: #ffffff;
  background: ${primaryColor};
  border-radius: 5px;
  border: none;
  height: 35px;
  margin-right: 4%;
  cursor: pointer;
  :hover {
    background: #faab2c;
  }

  ${mobile} {
    height: 30px;
  }
`;

export const InnerAddressContainer = styled.div`
  max-height: 320px;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
`;

export const AddressCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  margin: 2%;
  width: 42%;
  border: 2px solid ${(props: DeliveryCardProps) => (props.selected ? primaryColor : '#E4EBF4')};
  background: ${(props: DeliveryCardProps) => (props.selected ? '' : '#FAFAFA')};
  box-shadow: 2.19459px 2.19459px 16.4594px rgba(35, 54, 79, 0.1);
  border-radius: 4.10072px;

  @media screen and (max-width: 1218px) {
    width: 100%;
  }

  ${smallScreenLaptop} {
    width: 42%;
  }

  @media screen and (max-width: 704px) {
    width: 100%;
  }

  ${mobile} {
    width: 100%;
  }

  h3 {
    margin: 0;
    font-size: 15px;
    color: #607087;
  }
  p {
    margin: 0;
    font-size: 13px;
    color: #8196b3;
  }
`;

export const AddressCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  h3,
  p {
    padding: 2% 0;
  }
  margin-bottom: 2%;
`;

export const ControlButton = styled.button`
  display: flex;
  border: none;
  border-radius: 5px;
  padding: 5px 7px;
  background-color: transparent;
  align-content: center;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
  }
`;

export const Check = styled.div`
  display: flex;
  color: ${(props: DeliveryCardProps) => (props.selected ? primaryColor : '#8196B3')};
  span {
    font-size: 13px;
    margin-left: 5px;
    align-items: center;
    display: flex;
  }
  img {
    width: 20px;
  }
`;
