import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import {
  Container,
  FlexCenter,
  CounterContainer,
  Section,
  ProductImageCard,
  ImageSelector,
  RandomProductContainer,
  ToggleContainer,
  ToggleButton,
  ToggleContent,
  Description,
  ProductSpecifications,
  ProductCustomerReview,
  VariationContainer,
  DisplayRating,
  VariationLabel,
  CustomRadioButton,
  RightArrow,
  CustomRadioButtomContainer,
  OutOfStockDiv,
  Wrapper
} from './product.style';
import showAmount from '../../utils/priceFormat.utils';
import { formatImageUrl, formatName } from '../../utils/format.utils';
import { Colors, FontSizes } from '../../constants/theme';
import Loader from '../../components/LoaderComponent';
import Counter from '../../components/counter';
import { Button } from '../../GlobalStyles/forms.style';
import { AppState } from '../../store/reducers';
import { Text, Flex, Button as CustomButton, Ellipse, Span } from '../../GlobalStyles/CustomizableGlobal.style';
import BottomNav from '../../components/footer/bottomNav';
import Helmet from '../../components/header/Helmet';
import ReturnBox from '../../img/return-box.svg';
import CloseIcon from '../../img/close.svg';
import Header from '../../components/header/header';
import ShopHeader from '../../components/header/shopHeader';
import Star from '../../img/star-outline.svg';
import DeliveryVanGrey from '../../img/delivery-van-grey.svg';
import Payment from '../../img/payment.svg';
import useWindowDimensions from '../../utils/windowsDimension.utils';
import ComingSoonIcon1 from '../../img/soon.png';
import ComingSoonIcon2 from '../../img/sooon.png';
import ProductClass from './ProductClass';
import OutOfStock from '../../img/out-of-stock.svg';
import ItemTypeModal from '../../components/ItemTypeModal';
import { addDataToCartUtil } from '../../utils/addToCart.utils';
import BreadCrumb from '../../components/BreadCrumb';
import Image from '../../components/image';
import { GET_INVENTORY } from '../../schema/inventory.schema';
import { GET_INVENTORY_TYPE, InventoryType } from '../../interfaces/inventory.interface';
import BargainModal from '../../components/BargainModal';
import ProductsFromSeller from './ProductsFromSeller';
import { getInventoryPrice, PIECES, PIECES_AND_PACK } from '../../utils/inventory.utils';
import PriceFormatComponent from '../../components/PriceFormatComponent';
import { getItem } from '../../utils/localStorage.utils';
import { useSelector } from 'react-redux';
import { domainName } from '../../utils/server.utils';
import routes from '../../utils/urlRoutes.utils';
import LocationEntry from '../../components/LocationEntry';
import { OptionType } from '../../interfaces/FilterSelect.interface';
import { toggleSnackbarOpen } from '../../store/actions/snackbarActions';
import { GET_DELIVERY_FEE } from '../../schema/GetStateAndCity.schema';
import { GET_DELIVERY_FEE_TYPE } from '../../interfaces/shop.interface';
import { CREATE_MEMBER_ADDRESS, GET_DEFAULT_ADDRESS } from '../../schema/member.schema';
import { GET_DEFAULT_ADDRESS_TYPE } from '../../interfaces/address.interface';
import { Accordion } from '@szhsin/react-accordion';
import { AccordionItem, AccordionContainer } from './Accordion.styles';
import { resetFetch } from '../../store/actions/fetchAction';

const mapStateToProps = (state: AppState) => {
  return {
    cartItems: state.CartCounter.cartItems
  };
};

interface Props {
  addToCartFromLocalStorage: Function;
  resetCounter: Function;
  addToCart: (
    id: string,
    title: string,
    price: number,
    quantity: number,
    fixedUnitPrice: number,
    merchant: string,
    image: string,
    inventoryType: string,
    variationId: string,
    variationName: string
  ) => void;
  cartItems: any[];
}

interface ImageType {
  largeImageOnlineURL: string;
  mediumImageOnlineURL: string;
  smallImageOnlineURL: string;
}

interface ProductParams {
  url: string;
}

const ProductPage: FunctionComponent<Props> = ({ addToCart, cartItems }) => {
  const { url } = useParams<ProductParams>();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const cart = useSelector((state: AppState) => state.CartCounter.cartItems);

  const { primaryGrey, body, lightBlue, primaryColor, darkBlue, white, secondaryGrey, blackLight, overlayColor } = Colors;
  const { bigFont, subTitleFont, primaryFontSize, headingFont, detailsFontSize } = FontSizes;
  const [piecesAndPackInventoryType, setPiecesAndPackInventoryType] = useState<InventoryType | undefined>(PIECES);
  const [variationId, setVariationId] = useState<string>();
  const [variationName, setVariationName] = useState<string>();
  const [bargainOpen, toggleBargain] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<OptionType>();
  const [selectedCity, setSelectedCity] = useState<OptionType>();
  const [showLocation, setShowLocation] = useState<boolean>(false);
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [feeLoading, setFeeLoading] = useState<boolean>(false);
  const [isLocationChat, setLocationChat] = useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<'Description' | 'Specification' | 'CustomerReview' | 'ReturnPolicy'>('Description');
  const { width } = useWindowDimensions();
  const isSession = getItem('session');
  const dispatch = useDispatch();
  const client = useApolloClient();

  const { data, loading, error } = useQuery<GET_INVENTORY_TYPE>(GET_INVENTORY, {
    variables: { inventoryUrl: url },
    onCompleted: ({ getInventory }) => {
      setPiecesAndPackInventoryType(getInventory?.inventoryType === PIECES_AND_PACK ? PIECES : getInventory?.inventoryType);
    }
  });

  const selectedInventoryType =
    data?.getInventory?.inventoryType === PIECES_AND_PACK ? piecesAndPackInventoryType : data?.getInventory?.inventoryType;

  const inventoryId = data?.getInventory.inventoryId || '';
  const productName = formatName(data?.getInventory.inventoryName || '');
  const merchant = data?.getInventory.Shop.shopName || '';
  const shopPhone = data?.getInventory.Shop.shopPhone || '';
  const returnPolicy = data?.getInventory.returnPolicy || '';
  const isBargainEnabled = data?.getInventory.Shop.isBargainEnabled;
  const trackable = data?.getInventory.trackable;
  const packPrice = data?.getInventory.TrackableItem?.packPrice || 0;
  const unitPrice = data?.getInventory.TrackableItem?.unitPrice || 0;
  const sellingPrice = data?.getInventory.NonTrackableItem?.sellingPrice || 0;
  const description = data?.getInventory.inventoryDescription || '';
  const perPack = data?.getInventory.TrackableItem?.perPack;
  const variations = data?.getInventory.Variations;
  const isVariation = data?.getInventory.isVariation;
  const quantity = data?.getInventory.quantity;

  const requestDelivery = async (stateId: number, cityId: number, invId?: string) => {
    if (stateId && cityId) {
      setFeeLoading(true);
      client
        .query<GET_DELIVERY_FEE_TYPE>({
          query: GET_DELIVERY_FEE,
          variables: {
            cityId,
            stateId,
            inventoryId: inventoryId ?? invId,
            inventoryUrl: data?.getInventory.InventoryUrl.url
          }
        })
        .then(({ data }) => {
          setDeliveryFee(data.getDeliveryFee);
          setShowLocation(false);
          setFeeLoading(false);
        })
        .catch((e) => {
          setFeeLoading(false);
          if (e instanceof Error) {
            alert(`${e.message}`);
          } else {
            dispatch(toggleSnackbarOpen('An unknown error occurred'));
          }
        });
    } else {
      dispatch(toggleSnackbarOpen('Please select a state and city'));
    }
  };

  const { data: memberAddress, loading: memberLoading } = useQuery<GET_DEFAULT_ADDRESS_TYPE>(GET_DEFAULT_ADDRESS, {
    fetchPolicy: 'no-cache'
  });

  const [createMemberAddress] = useMutation<any>(CREATE_MEMBER_ADDRESS, {
    onCompleted() {
      dispatch(resetFetch());
    },
    onError: (e) => {
      dispatch(toggleSnackbarOpen(e.message));
    }
  });

  useEffect(() => {
    if (memberAddress?.getDefaultAddress && data) {
      const address = memberAddress?.getDefaultAddress;
      setSelectedState({ label: address?.State.stateName!, value: String(address?.State.stateId!) });
      setSelectedCity({ label: address?.City.cityName!, value: String(address?.City.cityId!) });
    }
  }, [memberAddress, data]);

  const handleCloseModal = (e: any) => {
    const locationWrapper = document.querySelector('.location-wrapper');
    if (e.target.isEqualNode(locationWrapper)) {
      setShowLocation(false);
      setFeeLoading(false);
    }
  };

  const handleRequestDelivery = async () => {
    if (selectedState?.value && selectedCity?.value) {
      try {
        await createMemberAddress({
          variables: { cityId: selectedCity?.value, stateId: selectedState?.value }
        }).then((res) => {
          if (res.data) {
            requestDelivery(Number(selectedState?.value), Number(selectedCity?.value));
            dispatch(toggleSnackbarOpen('Location added!'));
          }
        });
      } catch (e) {
        dispatch(toggleSnackbarOpen(e instanceof Error ? `${e}` : 'Unable to add address'));
      }
    }
  };

  const setStateAndCity = (state: OptionType, city: OptionType) => {
    setSelectedState(state);
    setSelectedCity(city);
  };

  useEffect(() => {
    if (data && memberAddress?.getDefaultAddress) {
      const {
        State: { stateName, stateId },
        City: { cityName, cityId }
      } = memberAddress.getDefaultAddress;
      const state = { label: stateName ?? '', value: String(stateId ?? '') };
      const city = { label: cityName ?? '', value: String(cityId ?? '') };
      setStateAndCity(state, city);
      requestDelivery(Number(state.value), Number(city.value));
    }
  }, [memberAddress, data]);

  const getSortedVariations = (): any => {
    if (variations?.length) {
      const sortedVariations = variations.slice().sort((a, b) => a.price - b.price);
      return sortedVariations;
    }
    return variations;
  };

  const sortedVariations = getSortedVariations();

  const [productPrice, setProductPrice] = useState<number | undefined>(!trackable ? sellingPrice : 0);

  const handlePiecesAndPackSelection = (value: InventoryType, price: number) => {
    setPiecesAndPackInventoryType(value);
    setProductPrice(price);
  };

  const existingCartItems = cart?.filter((cartItem) => {
    let updated;
    if (trackable && data?.getInventory.inventoryType === 'PIECES_AND_PACK') {
      if (cartItem.inventoryType === piecesAndPackInventoryType) {
        updated = cartItem.id === inventoryId;
      }
    } else {
      updated = cartItem.id === inventoryId;
    }

    return updated;
  });

  const totalItemInCart = existingCartItems?.reduce((accumulator, object) => {
    return accumulator + object.quantity;
  }, 0);

  const existingCartItem = existingCartItems?.[0];

  const imageRaw =
    (data &&
      data.getInventory.Images.length > 0 &&
      data.getInventory.Images[0].smallImageOnlineURL !== null &&
      data.getInventory.Images[0].smallImageOnlineURL) ||
    '';
  const imageUrl = formatImageUrl(String(imageRaw), 'product');
  const fixedUnitPrice = () => {
    return trackable ? (selectedInventoryType === 'PACK' ? packPrice : unitPrice) : isVariation ? productPrice : sellingPrice;
  };

  const inventoryPrice = selectedInventoryType
    ? getInventoryPrice(data?.getInventory?.InventoryPrice, selectedInventoryType)
    : { sellingPrice: null, discountPrice: null, bargainPrice: null };

  const handleAddingToCart = () => {
    const count = 1;
    let amount = fixedUnitPrice();
    if (selectedInventoryType) {
      addDataToCartUtil(
        cartItems,
        amount || 0,
        inventoryId,
        count,
        productName,
        fixedUnitPrice() || 0,
        merchant,
        imageUrl,
        selectedInventoryType,
        variationId || '',
        variationName || '',
        dispatch,
        inventoryPrice || { sellingPrice: null, discountPrice: null, bargainPrice: null },
        data?.getInventory?.inventoryType
      );
    } else {
      alert('Please select product Class');
    }
  };

  const handleBargain = () => {
    if (selectedInventoryType) {
      isSession ? toggleBargain(true) : history.push(`/login?redirect=${currentPath}`);
    } else {
      alert('Please select product Class');
    }
  };

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <RightArrow>
        <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />
      </RightArrow>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <RightArrow>
        <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />
      </RightArrow>
    );
  }
  const supplier = data?.getInventory.Shop;

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: function (index: number) {
      return (
        <ImageSelector>
          <img src={formatImageUrl(data?.getInventory.Images[index]?.smallImageOnlineURL || '', 'product')} alt="product" width={'100%'} />
        </ImageSelector>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false
  };

  const isOutOfStock: boolean = trackable ? (quantity ?? 0) <= 0 : false;

  const isLoading = () => {
    return loading || memberLoading || feeLoading;
  };

  const productUrl = domainName + location.pathname;

  // Temp fix: show on facebook pixel out of stock if product is not in Abuja
  const isOutOfStockForFacebook = isOutOfStock
    ? true
    : !(data?.getInventory?.Shop?.State?.stateId == 15 || supplier?.ShopURL?.shopTag?.includes('brand101'));

  const handleShowModal = () => {
    if (!isSession) {
      dispatch(toggleSnackbarOpen('You need to be logged in to set up location.'));
      history.push(`/login?redirect=${currentPath}`);
    }
    setShowLocation(true);
  };
  const getPrice = () => {
    if (isVariation && sortedVariations?.length) {
      return `${showAmount(sortedVariations[0]?.price)} - ${showAmount(sortedVariations[sortedVariations.length - 1]?.price)}`;
    }

    if (data?.getInventory.inventoryType === 'PIECES_AND_PACK' && !selectedInventoryType) {
      return `${showAmount(unitPrice)}}/${showAmount(packPrice)}`;
    }
    return `${showAmount(inventoryPrice?.sellingPrice!)}`;
  };
  const { nossr } = routes;

  if (loading) return <Loader />;
  if (!loading && error?.message === 'Product not found') {
    return <Redirect to={nossr.pageNotFound} />;
  }

  return (
    <div style={{ background: ' #FCFCFC' }}>
      {
        <Helmet
          title={data?.getInventory.inventoryName as string}
          description={data?.getInventory.inventoryDescription as string}
          imageUrl={formatImageUrl(data?.getInventory.Images[0].mediumImageOnlineURL, 'product')}
          type={'product'}
          price={inventoryPrice?.sellingPrice}
          inventoryId={inventoryId}
          url={productUrl}
          isOutOfStock={isOutOfStockForFacebook}
          brand={'Facebook'}
        />
      }
      <Header />
      <ShopHeader
        shopId={supplier?.shopId}
        name={supplier?.shopName || ''}
        location={supplier?.fullAddress || ''}
        distance={supplier?.distance}
        longitude={supplier?.longitude}
        latitude={supplier?.latitude}
        image={supplier?.Images ? supplier?.Images[0]?.mediumImageOnlineURL : ''}
        shopPhone={supplier?.shopPhone || ''}
        shopTag={supplier?.ShopURL?.shopTag || ''}
        imageUrl={imageUrl || ''}
        description={description}
        loading={loading}
        inventoryId={inventoryId}
        isProductLocationChat={isLocationChat}
        setProductLocationChat={setLocationChat}
        productName={productName}
        productPrice={getPrice()}
      />
      {/* removed banner till needed */}
      {/* <img style={{ width: '100%' }} src={Banner} alt="Banner" /> */}
      <Container>
        <Wrapper>
          {/* <BreadCrumb
            crumbs={[
              { name: 'home', route: '/' },
              { name: 'shops', route: '/merchant/1' },
              { name: `${supplier?.shopName}`, route: `/${supplier?.ShopURL?.shopTag}` },
              { name: `${productName}`, route: null }
            ]}
          /> */}

          {showLocation && (
            <Flex className="location-wrapper" onClick={handleCloseModal}>
              <Flex direction="column" margin="2rem 0 0 0" width="400px" className="location">
                <img
                  src={CloseIcon}
                  alt="close"
                  onClick={() => {
                    setShowLocation(false);
                    setFeeLoading(false);
                  }}
                />
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <LocationEntry
                    flexDirection="column"
                    padding="10px 0"
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                  />
                </Flex>
                <Button margin="1rem 0 0 0 " width="100%" cursor="pointer" onClick={handleRequestDelivery}>
                  Request Delivery Fee
                </Button>
              </Flex>
            </Flex>
          )}

          <div className="sections">
            <Section id="image-container">
              <ProductImageCard>
                <Slider {...settings}>
                  {data?.getInventory.Images.map((image: ImageType) => (
                    <div key={`image-${image.smallImageOnlineURL}`}>
                      <Image src={formatImageUrl(String(image.largeImageOnlineURL), 'product')} alt="product" />
                    </div>
                  ))}
                </Slider>
              </ProductImageCard>
            </Section>
            <Section id="detail-container">
              <Text textAlign="start" fontSize={subTitleFont} color={primaryGrey} margin="0">
                {productName}
              </Text>

              <div style={{ justifyContent: 'flex-start', display: 'flex' }}>
                <Flex className="price-bargain" justifyContent="space-between">
                  <div>
                    <div>
                      {isVariation && sortedVariations?.length ? (
                        <Text fontWeight={'700'} fontSize={headingFont} color={darkBlue} margin="0">
                          {showAmount(sortedVariations[0]?.price)} - {showAmount(sortedVariations[sortedVariations.length - 1]?.price)}
                        </Text>
                      ) : null}
                      {data?.getInventory.inventoryType === 'PIECES_AND_PACK' && !selectedInventoryType ? (
                        <Text fontWeight={'700'} fontSize={headingFont} color={darkBlue} margin=" 0">
                          {showAmount(unitPrice)}/{showAmount(packPrice)}
                        </Text>
                      ) : null}
                      {data?.getInventory.inventoryType === 'PIECES' ||
                      (data?.getInventory.inventoryType === 'PACK' && selectedInventoryType) ? (
                        <Text fontWeight={'700'} fontSize={headingFont} color={darkBlue} margin="0">
                          {showAmount(inventoryPrice?.sellingPrice!)}
                        </Text>
                      ) : null}
                      {data?.getInventory.inventoryType === 'PIECES_AND_PACK' && selectedInventoryType ? (
                        <PriceFormatComponent inventoryPrice={inventoryPrice} />
                      ) : null}
                    </div>
                    <DisplayRating>
                      <Flex alignItems="center">
                        {Array.from({ length: 5 }, (_, i) => {
                          return <img key={i} src={Star} alt="star" />;
                        })}
                      </Flex>
                      <span>No reviews yet</span>
                    </DisplayRating>
                    {trackable && data?.getInventory.inventoryType === 'PIECES_AND_PACK' && (
                      <ProductClass
                        perPack={perPack}
                        selectedClass={selectedInventoryType}
                        inventoryPrice={inventoryPrice}
                        handleChange={handlePiecesAndPackSelection}
                        inventoryType={data?.getInventory.inventoryType}
                      />
                    )}
                  </div>
                </Flex>
              </div>

              {variations?.length ? (
                <Flex direction="column" alignItems="start">
                  <VariationContainer>
                    <VariationLabel>Variations Available:</VariationLabel>
                    <CustomRadioButtomContainer>
                      {variations?.map((variation) => {
                        const { variationId, variationName, price } = variation;
                        return (
                          <CustomRadioButton key={variationId}>
                            <input
                              className="hide-radio"
                              type="radio"
                              name="sizeType"
                              id={variationId}
                              value={variationId}
                              onChange={() => setModalOpen(true)}
                            />
                            <label htmlFor={variationId} className="label-radio">
                              {variationName}
                            </label>
                          </CustomRadioButton>
                        );
                      })}
                    </CustomRadioButtomContainer>
                  </VariationContainer>
                </Flex>
              ) : null}

              {/* <Text color={lightBlue}>
              Bargain is available for this product. <Span color={primaryColor}>click</Span> on bargain to bargain with seller
              </Text> */}

              {isOutOfStock && (
                <OutOfStockDiv>
                  <img src={OutOfStock} alt="out of stock" />
                </OutOfStockDiv>
              )}

              <Flex direction="column" alignItems="flex-start" margin="">
                <Flex direction="column" alignItems="flex-start" margin="0 0 1rem 0" gap="5px">
                  <Text fontWeight={'600'} fontSize={primaryFontSize} margin={'0'} color={blackLight}>
                    Delivery Fee:
                  </Text>

                  {isLoading() ? (
                    <Text fontSize={primaryFontSize} color={overlayColor} margin={'0'}>
                      <em>Please wait...</em>
                    </Text>
                  ) : (
                    <Flex>
                      {!!deliveryFee && (
                        <Text fontSize={primaryFontSize} color={overlayColor} margin={'0'}>
                          <em>
                            {deliveryFee && showAmount(deliveryFee)} delivery to {selectedCity?.label}
                          </em>
                        </Text>
                      )}

                      {!deliveryFee && selectedCity?.value && (
                        <Text fontSize={primaryFontSize} color={overlayColor} margin={'0'}>
                          <em>
                            This merchant does not deliver to your location,{' '}
                            <span style={{ color: `${primaryColor}`, cursor: 'pointer' }} onClick={() => setLocationChat(true)}>
                              Click here
                            </span>{' '}
                            to contact merchant.
                          </em>
                        </Text>
                      )}

                      {!selectedCity?.value && deliveryFee < 1 && (
                        <Text fontSize={primaryFontSize} color={overlayColor} margin={'0'}>
                          <em>
                            No location set yet,{' '}
                            <span
                              style={{ color: `${primaryColor}`, cursor: 'pointer' }}
                              onClick={() => {
                                handleShowModal();
                                setFeeLoading(true);
                              }}
                            >
                              click here
                            </span>{' '}
                            to set location to view delivery price
                          </em>
                        </Text>
                      )}
                    </Flex>
                  )}
                </Flex>
                <Flex maxHeight="200px" direction="column" alignItems="flex-start">
                  <FlexCenter>
                    <Ellipse width="40px" height="40px" background="transparent">
                      <img src={Payment} alt="exchange icon" />
                    </Ellipse>
                    <Flex direction="column" alignItems="flex-start">
                      <Text fontWeight={'600'} fontSize={primaryFontSize} margin={'0 5px'} color={blackLight}>
                        100% Payment Security
                      </Text>
                      <Text fontSize={primaryFontSize} color={overlayColor} margin={'0 5px'}>
                        Payments on our platform is 100% safe and secured
                      </Text>
                    </Flex>
                  </FlexCenter>
                  <FlexCenter>
                    <Ellipse width="40px" height="40px" background="transparent">
                      <img src={DeliveryVanGrey} alt="exchange icon" />
                    </Ellipse>
                    <Flex direction="column" alignItems="flex-start">
                      <Text fontWeight={'600'} fontSize={primaryFontSize} margin={'0 5px'} color={blackLight}>
                        Everything at your doorstep
                      </Text>
                      <Text fontSize={primaryFontSize} color={overlayColor} margin={'0 5px'}>
                        Routine delivery to your doorstep
                      </Text>
                    </Flex>
                  </FlexCenter>
                </Flex>
              </Flex>

              <CounterContainer>
                {existingCartItem ? (
                  <div>
                    <Counter
                      inventoryId={inventoryId}
                      inventoryType={selectedInventoryType}
                      productName={productName}
                      imageurl={imageUrl}
                      merchant={merchant}
                      fixedPrice={productPrice || 0}
                      openModal={setModalOpen}
                      counterInitial={totalItemInCart}
                      inventoryPrice={data?.getInventory.InventoryPrice}
                    />
                    <Text color={body} fontSize={detailsFontSize}>
                      <em>Item already in cart</em>
                    </Text>
                  </div>
                ) : (
                  <div className="check-quantity-div">
                    <Button
                      id="add-to-cart"
                      margin={'0 1em'}
                      width={'45%'}
                      opacity={isOutOfStock ? '0.7' : '1'}
                      cursor={isOutOfStock ? 'not-allowed' : 'pointer'}
                      onClick={() => {
                        if (isOutOfStock) {
                          alert('Item is out of stock');
                          return;
                        }

                        selectedInventoryType === 'VARIATION' ? setModalOpen(true) : handleAddingToCart();
                      }}
                    >
                      Add To Cart
                    </Button>
                    {isBargainEnabled && (
                      <CustomButton
                        id="bargain-button"
                        width="45%"
                        color={white}
                        borderSize={'2px'}
                        borderRadius={'5px'}
                        backgroundColor="#000"
                        alignSelf="flex-end"
                        onClick={() => {
                          // return alert('Coming soon');
                          if (isOutOfStock || !fixedUnitPrice()) {
                            return;
                          }

                          handleBargain();
                        }}
                      >
                        <p>Bargain this Price</p>
                      </CustomButton>
                    )}
                  </div>
                )}
              </CounterContainer>
              {/* <hr /> */}
            </Section>
          </div>
        </Wrapper>
      </Container>
      <AccordionContainer>
        <Accordion transition transitionTimeout={200}>
          <AccordionItem header="Description" initialEntered>
            <Flex id="productInfo" justifyContent="start">
              <p style={{ color: lightBlue, marginRight: '10px' }}>{description ? description : 'No description for this product'}</p>
            </Flex>
          </AccordionItem>
          <AccordionItem header="Return Policy">
            <p>{returnPolicy ? returnPolicy : 'No return Policy for this product'}</p>
          </AccordionItem>
          <AccordionItem header="Customer Reviews">
            <ProductSpecifications style={{ display: 'flex', justifyContent: 'center' }}>
              <img style={{ margin: '2% 0' }} src={ComingSoonIcon2} alt="coming soon icon" />
            </ProductSpecifications>
          </AccordionItem>
        </Accordion>
      </AccordionContainer>

      <ToggleContainer>
        <ToggleButton
          isActive={currentTab === 'Description'}
          style={currentTab === 'Description' ? { color: '#FFA412', borderBottom: '2px solid #FFA412' } : {}}
          onClick={() => setCurrentTab('Description')}
        >
          Descriptions
        </ToggleButton>
        <ToggleButton
          isActive={currentTab === 'ReturnPolicy'}
          style={currentTab === 'ReturnPolicy' ? { color: '#FFA412', borderBottom: '2px solid #FFA412' } : {}}
          onClick={() => setCurrentTab('ReturnPolicy')}
        >
          Return Policy
        </ToggleButton>
        <ToggleButton
          isActive={currentTab === 'CustomerReview'}
          style={currentTab === 'CustomerReview' ? { color: '#FFA412', borderBottom: '2px solid #FFA412' } : {}}
          onClick={() => setCurrentTab('CustomerReview')}
        >
          Customer Reviews
        </ToggleButton>
      </ToggleContainer>

      <ToggleContent>
        {currentTab === 'Description' && (
          <Description>
            <p style={{ color: '#607087' }}>{description ? description : 'No description for this product'}</p>
          </Description>
        )}
        {currentTab === 'ReturnPolicy' && (
          <p style={{ color: '#607087' }}>{returnPolicy ? returnPolicy : 'No return Policy for this product'}</p>
        )}
        {currentTab === 'CustomerReview' && (
          <ProductCustomerReview style={{ display: 'flex', justifyContent: 'center' }}>
            <img style={{ margin: '2% 0' }} src={ComingSoonIcon1} alt="coming soon icon" />
          </ProductCustomerReview>
        )}
      </ToggleContent>

      <RandomProductContainer>
        <ProductsFromSeller shopTag={supplier?.ShopURL?.shopTag} shopId={supplier?.shopId} />
      </RandomProductContainer>
      <BottomNav />
      {bargainOpen && (
        <BargainModal
          toggleBargain={toggleBargain}
          product={data?.getInventory}
          shop={data?.getInventory?.Shop}
          inventoryType={selectedInventoryType}
          variationId={variationId}
        />
      )}
      {isModalOpen && (
        <ItemTypeModal
          setinventoryType={setPiecesAndPackInventoryType}
          inventoryType={selectedInventoryType}
          inventoryTypeProp={selectedInventoryType}
          setModalOpen={setModalOpen}
          addToCart={handleAddingToCart}
          unitPrice={unitPrice}
          packPrice={packPrice}
          Variations={sortedVariations}
          perPack={perPack || 0}
          inventoryId={inventoryId}
          productName={productName}
          imageurl={imageUrl}
          merchant={merchant}
          inventoryPrice={data?.getInventory.InventoryPrice}
        />
      )}
    </div>
  );
};

export default ProductPage;
