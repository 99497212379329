import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CartItemCard from '../CartItemCard';
import {
  TotalContainer,
  TotalText,
  OrderSummaryContainer,
  EmptyCart,
  CartContainer,
  SummaryContainer,
  ShopContainer,
  ShopTitle,
  CheckBox
} from './OrderSummary.style';
import { AppState } from '../../store/reducers/index';
import showAmount from '../../utils/priceFormat.utils';
import { Flex, Text, Button } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors } from '../../constants/theme';
import { clearCart } from '../../store/actions/cartCountAction';
import { getItem, setItem } from '../../utils/localStorage.utils';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { hasfailed, isloading, resetFetch } from '../../store/actions/fetchAction';
import cartImage from '../../img/prev-cart.svg';
import { getOrderItems } from '../../utils/order.utils';
import { addSelectFromCart } from '../../store/actions/addSelection';
import Loader from '../LoaderComponent';
import PopupCard from '../PopupCard';
import Login from '../../pages/LoginAndRegister/login';
import AuthPopup from './AuthPopup';

interface Props {
  onCheckoutPage?: boolean;
  clearCart: Function;
  paymentMethod?: string;
  deliveryOption?: string;
}

const GET_ORDER_FEE = gql`
  mutation getOrderFees($orderItems: [JSONObject], $paymentMethod: PaymentOption, $deliveryOption: DeliveryOption) {
    getOrderFees(orderItems: $orderItems, paymentMethod: $paymentMethod, deliveryOption: $deliveryOption) {
      deliveryFee
      transactionFee
      totalAmount
      subTotal
    }
  }
`;

interface GET_ORDER_FEE_TYPE {
  deliveryFee: number;
  transactionFee: number;
  totalAmount: number;
  subTotal: number;
}

const OrderSummary: FunctionComponent<Props> = ({ onCheckoutPage, clearCart, paymentMethod, deliveryOption }) => {
  const { body, primaryGrey, primaryColor } = Colors;
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderFees, setOrderFees] = useState<GET_ORDER_FEE_TYPE>();
  const cartItems = useSelector((state: AppState) => state.CartCounter.cartItems);
  const [sortedCartItems, setSortedCartItems] = useState<{ [key: string]: any }>({});
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const isSession = getItem('session');
  const dispatch = useDispatch();
  const history = useHistory();
  const savedSelectedItems = useSelector((state: AppState) => state.selectedCartItems.selectedCartItems);

  const [getOrderFee] = useMutation<any>(GET_ORDER_FEE, {
    onCompleted({ getOrderFees }) {
      setOrderFees(getOrderFees);
      dispatch(resetFetch());
    }
  });

  useEffect(() => {
    getTotal();
    if (onCheckoutPage && deliveryOption && paymentMethod) {
      orderFee();
    }
    if (!onCheckoutPage) {
      localStorage.removeItem('selectedItems');
    }
    sortItems();
  }, [cartItems, selectedItems, deliveryOption, paymentMethod]);

  useEffect(() => {
    setSelectedItems(cartItems);
  }, [cartItems]);

  const sortItems = () => {
    let sortedItems: { [key: string]: any } = {};
    cartItems.forEach((val: any) => {
      sortedItems[val?.merchant] && !sortedItems[val?.merchant].includes(val)
        ? sortedItems[val?.merchant].push(val)
        : (sortedItems[val?.merchant] = [val]);
    });
    setSortedCartItems(() => {
      return sortedItems;
    });
  };

  const getTotal = () => {
    const totalOfCartPrice = () => {
      let initialTotal = 0;
      selectedItems.reduce((amount: number, item: any) => {
        return (initialTotal = item?.price + amount);
      }, 0);
      return initialTotal;
    };
    const total = totalOfCartPrice();
    setTotalPrice(total);
    setItem('totalCartAmount', total);
  };

  const orderFee = () => {
    dispatch(isloading());
    try {
      getOrderFee({
        variables: {
          orderItems: getOrderItems(savedSelectedItems),
          paymentMethod: paymentMethod,
          deliveryOption: deliveryOption
        }
      });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  const allChecked = () => selectedItems.length === cartItems.length;
  const handleSelectAll = (allChecked: boolean) => {
    allChecked ? setSelectedItems([]) : setSelectedItems(cartItems);
  };

  const isChecked = (shop: string) => {
    let checked = true;
    for (let i = 0; i < sortedCartItems[shop].length; i++) {
      if (!selectedItems.includes(sortedCartItems[shop][i])) {
        checked = false;
        break;
      }
    }
    return checked;
  };

  const handleSelectShop = (shop: string, prevState: boolean) => {
    if (prevState) {
      setSelectedItems((prev) => {
        const copy = [...prev];
        sortedCartItems[shop].forEach((val: Object) => {
          copy.splice(copy.indexOf(val), 1);
        });
        return copy;
      });
    } else {
      sortedCartItems[shop].forEach((val: Object) => {
        if (!selectedItems.includes(val)) {
          setSelectedItems((prev) => {
            const copy = [...prev];
            copy.push(val);
            return copy;
          });
        }
      });
    }
  };

  const handleCheckItem = (item: Object) => {
    if (selectedItems.includes(item)) {
      setSelectedItems((prev) => {
        const copy = [...prev];
        copy.splice(selectedItems.indexOf(item), 1);
        return copy;
      });
    } else {
      setSelectedItems((prev) => {
        const copy = [...prev];
        copy.push(item);
        return copy;
      });
    }
  };

  const handleClearCart = () => {
    setItem('allCartItems', []);
    localStorage.removeItem('totalCartAmount');
    clearCart();
  };

  const [showAuth, setShowAuth] = useState<boolean>();

  const handleProceedToCheckout = async () => {
    dispatch(addSelectFromCart(selectedItems));
    if (isSession) {
      history.push('/checkout?type=checkout');
    } else {
      setShowAuth(true);
    }
  };

  const status = useSelector<AppState>((state) => state.fetch.status);

  if (status === 'loading') {
    return <Loader />;
  }

  if (cartItems && cartItems.length < 1)
    return (
      <EmptyCart>
        <img src={cartImage} alt="cart" />
        <h1>Looks like you have no items in your shopping cart.</h1>

        <Link to="/">
          <button>Continue Shopping</button>
        </Link>
      </EmptyCart>
    );

  return (
    <CartContainer>
      <OrderSummaryContainer onCheckoutPage={onCheckoutPage}>
        <Flex justifyContent="space-between" style={{ margin: '0 0 15px 0' }}>
          <Flex>
            <Text color={body} fontSize="18px" fontWeight="600" margin="0 10px 0 0">
              Cart ({cartItems.length})
            </Text>
            {!onCheckoutPage && (
              <>
                <CheckBox htmlFor="toggle-all" checked={allChecked()} onClick={() => handleSelectAll(allChecked())}>
                  <span></span>
                </CheckBox>
                <input type="checkbox" id="toggle-all" hidden />
                {allChecked() ? (
                  <Text fontSize="15px" color={primaryColor} margin="0 0 0 5px">
                    Unselect all
                  </Text>
                ) : (
                  <Text fontSize="15px" color={primaryGrey} margin="0 0 0 5px">
                    Select All
                  </Text>
                )}
              </>
            )}
          </Flex>
          <Flex id="cart-header-text" justifyContent="space-between">
            {/* <Text id="clear-cart" className="cart-text" color={secondaryColor} fontSize={secondaryFontSize} onClick={handleClearCart}>
              Clear Cart
            </Text> */}
            <Link to="/" id="continue-shopping">
              Continue Shopping
            </Link>
          </Flex>
        </Flex>
        {!onCheckoutPage &&
          cartItems &&
          cartItems.length > 0 &&
          Object.keys(sortedCartItems).map((shopName, i) => {
            return (
              <ShopContainer key={i}>
                <ShopTitle>
                  <CheckBox
                    htmlFor={shopName}
                    checked={isChecked(shopName)}
                    onClick={() => handleSelectShop(shopName, isChecked(shopName))}
                  >
                    <span></span>
                  </CheckBox>
                  <input type="checkbox" id={shopName} hidden />
                  {/* <Link to={`/${shopName}`}> */}
                  <p>{shopName}</p>
                  {/* </Link> */}
                  {/* <a>
                      <img src={messageImage} />
                      Contact Seller
                    </a> */}
                </ShopTitle>
                {sortedCartItems[shopName].map((item: { [key: string]: any }, index: any) => {
                  const {
                    id,
                    title,
                    price,
                    merchant,
                    image,
                    inventoryType,
                    variationName,
                    quantity,
                    variationId,
                    inventoryPrice,
                    initialInventoryType
                  } = item || {};
                  return (
                    <CartItemCard
                      key={`${id}-${index}`}
                      id={id}
                      title={title}
                      price={price}
                      onCheckoutPage={onCheckoutPage}
                      checked={selectedItems.includes(item)}
                      image={image}
                      getTotal={getTotal}
                      handleCheck={() => handleCheckItem(item)}
                      inventoryType={inventoryType}
                      merchant={merchant}
                      quantity={quantity}
                      variationName={variationName}
                      variationId={variationId}
                      inventoryPricing={inventoryPrice}
                      initialInventoryType={initialInventoryType || inventoryType}
                    />
                  );
                })}
              </ShopContainer>
            );
          })}
        {onCheckoutPage &&
          savedSelectedItems.map((item: { [key: string]: any }) => {
            const {
              id,
              title,
              price,
              merchant,
              image,
              inventoryType,
              quantity,
              variationName,
              variationId,
              inventoryPrice,
              initialInventoryType
            } = item;
            return (
              <CartItemCard
                key={id}
                id={id}
                title={title}
                price={price}
                onCheckoutPage={onCheckoutPage}
                checked={true}
                image={image}
                getTotal={getTotal}
                handleCheck={() => handleCheckItem(item)}
                inventoryType={inventoryType}
                merchant={merchant}
                quantity={quantity}
                variationName={variationName}
                variationId={variationId}
                inventoryPricing={inventoryPrice}
                initialInventoryType={initialInventoryType || inventoryType}
              />
            );
          })}
      </OrderSummaryContainer>
      {cartItems && cartItems.length > 0 && (
        <SummaryContainer>
          <h2>Order Summary</h2>
          <TotalContainer>
            <TotalText>Sub Total</TotalText>
            <TotalText>{showAmount(orderFees?.subTotal || totalPrice)}</TotalText>
          </TotalContainer>
          {onCheckoutPage && orderFees && (
            <>
              <TotalContainer>
                <TotalText>Delivery Fee</TotalText>
                <TotalText>{showAmount(orderFees?.deliveryFee || 0)}</TotalText>
              </TotalContainer>
              <TotalContainer>
                <TotalText>Processing Fee</TotalText>
                <TotalText>{showAmount(orderFees?.transactionFee || 0)}</TotalText>
              </TotalContainer>
            </>
          )}
          <TotalContainer style={{ margin: '25px 0' }}>
            <TotalText label>Total</TotalText>
            <TotalText label fontSize="25px">
              {showAmount(orderFees?.totalAmount || totalPrice)}
            </TotalText>
          </TotalContainer>
          <Text color={primaryColor} id="delivery-info">
            Transaction and delivery fees will be added at checkout
          </Text>
          {!onCheckoutPage && (
            <div onClick={handleProceedToCheckout} className={`cart-buttons ${selectedItems.length === 0 ? 'disabled-link' : ''}`}>
              <Button disable={selectedItems.length === 0} backgroundColor={primaryColor} borderRadius="5px" width="100%">
                Buy ({selectedItems.length})
              </Button>
            </div>
          )}
        </SummaryContainer>
      )}

      {showAuth && <AuthPopup close={() => setShowAuth(false)} />}
    </CartContainer>
  );
};

export default connect(null, { clearCart })(OrderSummary);
