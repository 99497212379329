import React, { FunctionComponent } from 'react';
import { gql, useQuery } from '@apollo/client';
import { RandomProductsContents, RandomProductsWrapper, SectionWrapper } from './TopGadget.style';
import ProductCard from '../../../components/ProductCard';
import Loader from '../../../components/LoaderComponent';
import { Colors } from '../../../constants/theme';
import getProductPrice from '../../../interfaces/GetProductPrice.interface';
import { Inventory, SEARCH_INVENTORY_TYPE } from '../../../interfaces/inventory.interface';
import { GET_INVENTORIES_AT_RANDOM_SCHEMA, SEARCH_INVENTORY } from '../../../schema/inventory.schema';
import whiteLogo from '../../../img/logo-white.png';
import arrowRight from '../../../img/arrow-grey.svg';
import searchIcon from '../../../img/search-b.svg';
import locationIcon from '../../../img/location.svg';
import { limit } from '../../../constants/limit.constant';
import { Link } from 'react-router-dom';
import getGeoLocationPosition from '../../../utils/queryLocation.utils';
import { Header } from '../TopProductsList/TopProductList.style';
import { getItem } from '../../../utils/localStorage.utils';
import { useUserLocation } from '../../../hooks/locationContext';

const TopGadget: FunctionComponent = () => {
  const { body } = Colors;
  const { currentCity, currentState } = useUserLocation() || {};

  // const { data, loading } = useQuery<{ getInventoriesAtRandom: Inventory[] }>(GET_INVENTORIES_AT_RANDOM_SCHEMA, {
  //   variables: {
  //     limit: 12
  //   }
  // });
  const position = getGeoLocationPosition();
  const { data, loading } = useQuery<SEARCH_INVENTORY_TYPE>(SEARCH_INVENTORY, {
    variables: {
      category: 'COMPUTING',
      page: 1,
      limit: 12,
      sortBy: 'RELEVANCE',
      searchType: 'INVENTORY',
      stateId: currentState?.value,
      cityId: currentCity?.value
    }
  });

  if (loading) return <Loader />;

  const location = 'Minna, Niger state';

  return (
    <RandomProductsWrapper>
      {/* <Header>
        <p>
          <img src={searchIcon} alt="" />
          <span>Top pick gadget deals</span>
        </p>{' '}
        <p id="location">
          <img src={locationIcon} alt="" />
          <span>Location</span>
        </p>
      </Header> */}
      <SectionWrapper>
        <RandomProductsContents>
          {data?.searchInventoryOrShop.inventories.map((product) => {
            const { inventoryId, inventoryType, Variations } = product;
            const variationId = Variations.length && Variations?.[0].variationId;

            return (
              <ProductCard
                key={inventoryId}
                price={getProductPrice(product, inventoryType || 'NON_TRACKABLE', variationId)}
                inventory={product}
              />
            );
          })}
        </RandomProductsContents>
      </SectionWrapper>
    </RandomProductsWrapper>
  );
};

export default TopGadget;
