import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import {
  AccountPageSidebar,
  AccountPageTop,
  PageContent,
  MainAccountContent,
  NavContainer,
  NavigationLink,
  NavImage,
  NavHeaderText,
  NavigationLinkContainer,
  DisplayCard,
  Flex
} from './account.style';
import { isloading, hasfailed, resetFetch } from '../../store/actions/fetchAction';
import { Button } from '../../GlobalStyles/CustomizableGlobal.style';
import { Overlay } from '../../components/SideSlide/SideSlide.style';
import { AppState } from '../../store/reducers';
import PopupCard from '../../components/PopupCard';
import Loader from '../../components/LoaderComponent';
import ErrorComponent from '../../components/ErrorComponent';
import { Helmet } from 'react-helmet-async';
import { Title } from '../../constants/meta.constant';
import Header from '../../components/header/header';
import AuthenticatedHOC from '../../components/AuthenticatedHOC';
import userIcon from '../../img/account-user.svg';
import orderHistoryIcon from '../../img/account-order.svg';
import messageIcon from '../../img/account-msg.svg';
import signOutIcon from '../../img/account-logout.svg';
import { GET_MEMBER_TYPE } from '../../interfaces/GetMember.interface';
import { GET_MEMBER_SCHEMA, LOGOUT_MEMBER_SCHEMA } from '../../schema/member.schema';
import AccountInfo from './AccountInfo';
import BreadCrumb from '../../components/BreadCrumb';
import ChangeAddress from './AddressBook';
import ChangePassword from './ChangePassword';
import AddressForm from '../../components/AddressForm';
import OrderHistory from './OrderHistory';
import Messages from './messages';
import { getItem } from '../../utils/localStorage.utils';
import { getWindowSize } from '../../utils/getWindow.utils';
import { BgMainContainer } from '../../GlobalStyles/FixedGlobal.style';
import SettledOrders from './SettledOrders';

interface AccountParams {
  tab: string;
}

const MyAccount: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const { tab } = useParams<AccountParams>();

  const [showChangeAddress, setShowChangeAddress] = useState(false);
  const [showFormAddress, setShowFormAddress] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState(0);
  const [addressIdToEdit, setAddressIdToEdit] = useState('');

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isMobile = windowSize.innerWidth <= 576;

  const status = useSelector<AppState>((state) => state.fetch.status);
  const message = useSelector<AppState>((state) => state.fetch.message);
  const dispatch = useDispatch();
  const lastShop = JSON.parse(getItem('last-shop') || '{}');

  const clearUser = () => {
    localStorage.removeItem('session');
    localStorage.removeItem('last-shop');
    localStorage.removeItem('defaultAddress');
    history.push('/login');
    dispatch(resetFetch());
  };

  const { data, loading, error, refetch } = useQuery<GET_MEMBER_TYPE>(GET_MEMBER_SCHEMA, {
    fetchPolicy: 'no-cache'
  });
  if (error) {
    clearUser();
  }

  const [logout] = useMutation<any>(LOGOUT_MEMBER_SCHEMA, {
    onCompleted({ logout }) {
      clearUser();
    }
  });

  const handleLogout = async () => {
    dispatch(isloading());
    try {
      await logout();
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  const toggleSidebar = async () => {
    setShow(!show);
  };

  if (loading) return <Loader />;
  return (
    <BgMainContainer>
      <Helmet>
        <title>{`${Title} | My Account`}</title>
        <meta name="description" content="Look at a summary of all your orders on timart ecommerce" />
      </Helmet>
      <Header />
      <AccountPageTop>
        <BreadCrumb
          crumbs={[
            { name: 'home', route: '/' },
            { name: 'my account', route: null }
          ]}
        />
        <Button padding="2px 6px" width={'60px'} height={'25px'} borderRadius={'8px'} className="sidebar-button" onClick={toggleSidebar}>
          Menu
        </Button>
        <PageContent>
          <AccountPageSidebar className={show ? 'show' : ''}>
            <NavContainer>
              <NavImage>
                <img src={userIcon} alt="" />
              </NavImage>
              <NavigationLinkContainer>
                <NavHeaderText>My Timart Account</NavHeaderText>
                <NavigationLink
                  onClick={() => {
                    history.push('/my-account/account-info');
                    setShow(false);
                  }}
                  active={tab === 'account-info' ? true : false}
                >
                  Account Information
                </NavigationLink>
                <NavigationLink
                  onClick={() => {
                    history.push('/my-account/address-book');
                    setShow(false);
                  }}
                  active={tab === 'address-book' ? true : false}
                >
                  Address Book
                </NavigationLink>
                <NavigationLink
                  onClick={() => {
                    history.push('/my-account/change-password');
                    setShow(false);
                  }}
                  active={tab === 'change-password' ? true : false}
                >
                  Change Password
                </NavigationLink>
              </NavigationLinkContainer>
            </NavContainer>
            <NavContainer>
              <NavImage>
                <img src={orderHistoryIcon} alt="" />
              </NavImage>
              <NavigationLinkContainer>
                <NavigationLink
                  margin="0px"
                  onClick={() => {
                    history.push('/my-account/order-history');
                    setShow(false);
                  }}
                  active={tab === 'order-history' ? true : false}
                >
                  <NavHeaderText>Order History</NavHeaderText>
                </NavigationLink>
              </NavigationLinkContainer>
            </NavContainer>
            <NavContainer>
              <NavImage>
                <img src={messageIcon} alt="" />
              </NavImage>
              <NavigationLinkContainer>
                <NavigationLink
                  margin="0px"
                  onClick={() => {
                    history.push(`/my-account/messages?shop=${lastShop.shopId || ''}`);
                    setShow(false);
                  }}
                  active={tab === 'messages' ? true : false}
                >
                  <NavHeaderText>Messages</NavHeaderText>
                </NavigationLink>
              </NavigationLinkContainer>
            </NavContainer>
            <NavContainer style={{ color: 'red' }} marginTop="20px">
              <NavImage>
                <img src={signOutIcon} alt="" />
              </NavImage>
              <NavigationLinkContainer>
                <NavigationLink margin="0px" onClick={handleLogout}>
                  <NavHeaderText style={{ color: 'red' }}>Logout</NavHeaderText>
                </NavigationLink>
              </NavigationLinkContainer>
            </NavContainer>
          </AccountPageSidebar>
          {show && <Overlay onClick={toggleSidebar} />}
          <DisplayCard>
            {tab === 'order-history' && (
              <MainAccountContent>
                <OrderHistory isMobile={isMobile} memberData={data} />
              </MainAccountContent>
            )}
            {tab === 'account-info' && (
              <MainAccountContent>
                <AccountInfo data={data} />
              </MainAccountContent>
            )}
            {tab === 'address-book' && (
              <MainAccountContent>
                <ChangeAddress
                  user={data?.getMember}
                  setShow={setShowChangeAddress}
                  setShowAddAddress={setShowFormAddress}
                  setIndexToEdit={setIndexToEdit}
                  setAddressIdToEdit={setAddressIdToEdit}
                  refetch={refetch}
                />
              </MainAccountContent>
            )}
            {tab === 'change-password' && <ChangePassword />}
            {tab === 'messages' && <Messages isMobile={isMobile} />}
            {showFormAddress && (
              <PopupCard close={() => setShowFormAddress(false)}>
                <AddressForm
                  setShow={setShowFormAddress}
                  user={data?.getMember}
                  indexToEdit={indexToEdit}
                  addressIdToEdit={addressIdToEdit}
                  refetch={refetch}
                />
              </PopupCard>
            )}
          </DisplayCard>
          <div className="background-container">
            <div className="left-content"></div>
            <div className="right-content"></div>
          </div>
        </PageContent>
      </AccountPageTop>

      {status === 'loading' && (
        <PopupCard transparent>
          <Loader />
        </PopupCard>
      )}
      {status === 'error' && (
        <PopupCard>
          <ErrorComponent message={String(message)} />
        </PopupCard>
      )}
    </BgMainContainer>
  );
};

export default AuthenticatedHOC(MyAccount);
