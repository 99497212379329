import React, { FunctionComponent, useState } from 'react';
import { Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import Error from '../ErrorComponent';
import Loader from '../LoaderComponent';
import { ClippedText, InputContainer, InputField, LocationHeader, SearchButton, StateCityContainer } from './StateCityModal.style';
import search from '../../img/search.svg';
import arrow from '../../img/chev-right.svg';
import { formatName } from '../../utils/format.utils';
import { setItem } from '../../utils/localStorage.utils';
import { Colors } from '../../constants/theme';
import { useHistory } from 'react-router-dom';
import { StateAndCityType } from '../../interfaces/FilterSelect.interface';
import { useQuery } from '@apollo/client';
import { GET_CITIES_WITH_INVENTORYCOUNT_TYPES, GET_STATES_WITH_INVENTORYCOUNT_TYPES } from '../../interfaces/GetStateAndCity.interface';
import { GET_CITIES_WITH_INVENTORYCOUNT, GET_STATES_WITH_INVENTORYCOUNT } from '../../schema/GetStateAndCity.schema';
import useWindowDimensions from '../../utils/windowsDimension.utils';
import ArrowBack from '../../img/arrow-back.svg';
import ChevRightDark from '../../img/chev-right-dark.svg';
import ChevRightLight from '../../img/chev-right-light.svg';
import Close from '../../img/close.svg';
import showAmount from '../../utils/priceFormat.utils';
import { isLocationSetKey } from '../header/navBar/userLocation';

interface Props {
  setPopup?: Function;
  setCurrentState?: Function;
  setCurrentCity?: Function;
  setLocationSet?: Function;
}

const StateCityModal: FunctionComponent<Props> = ({ setPopup, setCurrentState, setCurrentCity, setLocationSet }) => {
  const history = useHistory();

  const [tab, setTab] = useState<string>('state');
  const [searchInput, setSearchInput] = useState();
  const [grid, setGrid] = useState(4);
  const [selectedState, setSelectedState] = useState<StateAndCityType>();

  const { width } = useWindowDimensions();
  const isMobile = width < 577;

  const { primaryGrey, black, white, lightBlue } = Colors;

  const {
    data: statesData,
    loading: statesLoading,
    error: statesError
  } = useQuery<GET_STATES_WITH_INVENTORYCOUNT_TYPES>(GET_STATES_WITH_INVENTORYCOUNT);
  const {
    data: citiesData,
    loading: citiesLoading,
    error: citiesError
  } = useQuery<GET_CITIES_WITH_INVENTORYCOUNT_TYPES>(GET_CITIES_WITH_INVENTORYCOUNT, {
    variables: {
      stateId: selectedState?.value
    }
  });

  const stateOptions = statesData?.getStatesWithInventoryCount
    .slice(1)
    .filter((state) => {
      if (searchInput) {
        return state.stateName.toLowerCase().includes(`${searchInput}`.toLowerCase());
      }
      return state;
    })
    .sort()
    .map((state) => {
      return { value: state.stateId, label: state.stateName, totalInventoryCount: state.totalInventoryCount };
    });

  const cityOptions = citiesData?.getCitiesWithInventoryCount
    .filter((city) => {
      if (searchInput) {
        return city.cityName.toLowerCase().includes(`${searchInput}`.toLowerCase());
      }
      return city;
    })
    .map((city) => {
      return { value: city.cityId, label: city.cityName, totalInventoryCount: city.totalInventoryCount };
    });

  const clearInput = () => {
    (document.getElementById('location-filter') as HTMLFormElement).reset();
  };

  const handleSetLocation = (city: StateAndCityType | null) => {
    setItem('userLocation', { selectedState, selectedCity: city });
    setSearchInput(undefined);
    setCurrentCity!(city);
    history.push('/searchQuery');
    setLocationSet!(true);
    setPopup!(false);
    clearInput();
  };

  const handleSelectState = (state: StateAndCityType | undefined) => {
    setSelectedState(state);
    setTab('city');
    setCurrentState!(state);
    setSearchInput(undefined);
    clearInput();
    setGrid(3);
  };

  const handleInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const goBack = () => {
    history.goBack();
  };

  const showAllLocation = () => {
    localStorage.removeItem('userLocation');
    setCurrentCity!(null);
    setCurrentState!(null);
    setLocationSet!(true);
    if (setPopup) {
      setPopup(false);
    }
    history.push('/');
  };

  const totalItemsCount = stateOptions?.reduce((acc, current) => {
    return acc + current.totalInventoryCount;
  }, 0);

  return (
    <StateCityContainer grid={grid}>
      <Flex alignItems="unset" justifyContent="center">
        <LocationHeader>
          {tab === 'city' && (
            <img
              onClick={() => {
                setTab('state');
                setGrid(4);
              }}
              className="back-arrow-desktop"
              src={ArrowBack}
            />
          )}
          <Text className="header-text" color={primaryGrey} fontSize={'1rem'} onClick={showAllLocation}>
            All Products in Nigeria {totalItemsCount && <span>({totalItemsCount})</span>}
          </Text>
          <img onClick={goBack} className="back-arrow-mobile" src={ArrowBack} />
          <form id="location-filter">
            <InputContainer>
              <div>
                <InputField
                  defaultValue={searchInput}
                  onChange={handleInputChange}
                  type="text"
                  placeholder={`Find ${tab === 'city' ? 'city or district' : tab}`}
                />
              </div>
              <div className="divider" />
              <SearchButton type="submit">
                <img src={search} alt="searchIcon" />
              </SearchButton>
            </InputContainer>
          </form>
        </LocationHeader>
      </Flex>
      {statesLoading || citiesLoading ? (
        <Loader />
      ) : (
        <>
          {tab === 'state' ? (
            <>
              <div className="grid-container">
                <Flex
                  gap="2em"
                  justifyContent="space-between"
                  padding="0 0 0 0.5rem"
                  cursor="pointer"
                  className="state-city-item all-state"
                  onClick={showAllLocation}
                >
                  <Text className="city-state-text" color={primaryGrey} margin="5px 0">
                    All Nigeria
                  </Text>
                  <img src={ChevRightDark} />
                </Flex>
                <Flex
                  width="100%"
                  gap="2em"
                  justifyContent="space-between"
                  cursor="pointer"
                  className="state-city-item all-state"
                  background={black}
                >
                  <Text margin="0.5rem 0" className="city-state-text" color={white}>
                    States & FCT
                  </Text>
                </Flex>
                {stateOptions?.map((state) => (
                  <div className="grid-item" onClick={() => handleSelectState(state)} key={state.value}>
                    <Flex>
                      <Text className="city-state-text" color={primaryGrey} margin="0 5px 0 0">
                        {formatName(state.label === 'Federal Capital Territory' ? 'FCT-Abuja' : state.label)}
                      </Text>
                      <Text className="count-text" color="#8196B3" margin="0">
                        ({showAmount(state?.totalInventoryCount!, '')} items)
                      </Text>
                    </Flex>
                    <img src={ChevRightLight} />
                  </div>
                ))}
              </div>
              {statesError && <Error disableClick message={statesError.message} />}
              {!stateOptions?.length && <Error disableClick message={'No match found'} />}
            </>
          ) : null}

          {tab === 'city' ? (
            <>
              <div className="grid-container">
                <Flex
                  gap="10px"
                  className="grid-item"
                  justifyContent="space-between"
                  maxHeight="40px"
                  cursor="pointer"
                  onClick={() => handleSetLocation(null)}
                >
                  <Text className="city-state-text" color={primaryGrey}>
                    All Cities in {selectedState?.label} ({selectedState?.totalInventoryCount})
                  </Text>
                  <img src={isMobile ? ChevRightDark : arrow} />
                </Flex>
                {cityOptions?.sort().map((city) => (
                  <div className="grid-item" onClick={() => handleSetLocation(city)}>
                    <Flex position="relative">
                      <ClippedText className="city-state-text" data-tip={formatName(city.label)}>
                        {formatName(city.label)}
                      </ClippedText>
                      <Text className="count-text" color="#8196B3" margin="0">
                        ({showAmount(city?.totalInventoryCount!, '')} items)
                      </Text>
                    </Flex>

                    <img src={isMobile ? ChevRightLight : arrow} />
                  </div>
                ))}
              </div>

              {citiesError && <Error disableClick message={citiesError.message} />}
              {!cityOptions?.length && <Error disableClick message={'No match found'} />}
            </>
          ) : null}
        </>
      )}
    </StateCityContainer>
  );
};

export default StateCityModal;
