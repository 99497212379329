import React, { FunctionComponent, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, Link, withRouter } from 'react-router-dom';
import { ProductInfo, ProductText, CartBadgeContainer, ImgContainer, MainContainer, OutOfStockBanner, Absolute } from './ProductCard.style';
import showAmount from '../../utils/priceFormat.utils';
import { currentShopInventoryIdAction } from '../../store/actions/shopInventoryIdAction';
import { formatImageUrl } from '../../utils/format.utils';
import { Colors, FontSizes } from '../../constants/theme';
import CartIcon from '../../img/cart-badge.svg';
import { AppState } from '../../store/reducers/index';
import { getItem, setItem } from '../../utils/localStorage.utils';
import { addToCartFromLocalStorage, addToCart } from '../../store/actions/cartCountAction';
import { removeAllModalRoutes } from '../../utils/customRoute.utils';
import DefaultProductImage from '../../img/timart-image-load.svg';
import pointer from '../../img/pointer-down.svg';
import ItemTypeModal from '../ItemTypeModal';
import { StatusView, Span, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { toggleSnackbarOpen } from '../../store/actions/snackbarActions';
import formatDistance from '../../utils/distanceFromat.utils';
import ShopIcon from '../../img/shop-icon.svg';
import { BsCartPlusFill } from 'react-icons/bs';
import OutOfStock from '../../img/out-of-stock.svg';
import FobiddenIcon from '../../img/forbidden-icon.svg';
import Image from '../image';
import { Inventory, InventoryPricingType } from '../../interfaces/inventory.interface';
import { getInventoryPrice } from '../../utils/inventory.utils';
import { Shop } from '../../interfaces/shop.interface';

interface Props {
  price: number;
  inventory: Inventory;
  shop?: Shop;
}

const ProductCard: FunctionComponent<Props & RouteComponentProps> = ({ price, inventory, shop }) => {
  const { priceColor, primaryGrey, lightGrey, lightBlue, red, lightRed } = Colors;
  const { primaryFontSize, descriptionFontSize, detailsFontSize } = FontSizes;
  const cartItems = useSelector((state: AppState) => state.CartCounter.cartItems);
  const count = 1;
  const dispatch = useDispatch();
  const imageUrl = formatImageUrl(inventory.Images[0]?.mediumImageOnlineURL ?? '', 'product');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [variationPrice, setVariationPrice] = useState();
  const variationId = '';
  const variationName = '';

  const {
    isVariation,
    NonTrackableItem,
    quantityInPacks,
    quantityInPieces,
    Variations,
    quantity,
    inventoryName,
    inventoryId,
    trackable,
    Shop,
    InventoryUrl,
    TrackableItem,
    InventoryPrice
  } = inventory;
  const { sellingPrice } = NonTrackableItem || {};
  const { perPack } = TrackableItem || {};
  const { shopName, shopAddress, distance } = Shop || shop || {};
  const inventoryTypeProp = inventory.inventoryType;

  const sortedVariations = Variations.slice().sort((a, b) => a.price - b.price);
  const variationMax = sortedVariations[sortedVariations.length - 1]?.price;
  const variationMin = sortedVariations[0]?.price;
  const unitPrice = getInventoryPrice(inventory.InventoryPrice, 'PIECES')?.sellingPrice || 0;
  const packPrice = getInventoryPrice(inventory.InventoryPrice, 'PACK')?.sellingPrice || 0;
  const [inventoryType, setinventoryType] = useState<string>(inventoryTypeProp);

  const fixedUnitPrice = () => {
    return trackable ? (inventoryType === 'PACK' ? packPrice : unitPrice) : isVariation ? variationPrice : sellingPrice;
  };

  const validInventoryType = ['PIECES', 'PACK', 'NON_TRACKABLE'];

  const inventoryPricing = validInventoryType.includes(inventoryType)
    ? getInventoryPrice(inventory?.InventoryPrice, inventoryType)
    : { sellingPrice: null, bargainPrice: null, discountPrice: null };

  const addDataToCart = (price: number) => {
    if (cartItems !== undefined) {
      let existingCartItem = cartItems.find((cartItem) => {
        let updated = cartItem.id === inventoryId;
        return updated;
      });

      if (existingCartItem) {
        existingCartItem.quantity = existingCartItem.quantity + count;
        existingCartItem.price = existingCartItem.price * count;

        let index = cartItems.map((cartItem) => cartItem.id).indexOf(existingCartItem.id);
        cartItems[index] = existingCartItem;
        setItem('allCartItems', cartItems);
        if (getItem('allCartItems')) {
          let allCartItems = JSON.parse(getItem('allCartItems') || '[]');
          dispatch(addToCartFromLocalStorage(allCartItems));
        }
      } else {
        dispatch(
          addToCart(
            inventoryId,
            inventoryName,
            price,
            count,
            fixedUnitPrice() || 0,
            shopName || '',
            imageUrl,
            inventoryTypeProp,
            variationId,
            variationName,
            inventoryPricing
          )
        );
      }
    } else {
      dispatch(
        addToCart(
          inventoryId,
          inventoryName,
          price,
          count,
          fixedUnitPrice() || 0,
          shopName || '',
          imageUrl,
          inventoryTypeProp,
          variationId,
          variationName,
          inventoryPricing
        )
      );
    }
    removeAllModalRoutes();
    dispatch(toggleSnackbarOpen('Added to cart'));
  };

  const handleAddToCart = () => {
    if (validInventoryType.includes(inventoryTypeProp)) {
      if (isOutOfStock || !fixedUnitPrice()) {
        return null;
      } else {
        addDataToCart(fixedUnitPrice() || 0);
      }
    } else {
      const amount = inventoryType === 'VARIATION' ? variationMin : fixedUnitPrice();
      if (!amount || isOutOfStock) {
        return null;
      } else {
        setModalOpen(true);
      }
    }
  };

  const checkIsOutOfStock = (): boolean => {
    if (!trackable) {
      return false;
    }

    if (inventoryTypeProp == 'PIECES_AND_PACK') {
      return (
        (quantityInPacks === null || (quantityInPacks as number) < 1) && (quantityInPieces === null || (quantityInPieces as number) < 1)
      );
    }

    if (inventoryTypeProp == 'PIECES') {
      return quantityInPieces === null || (quantityInPieces as number) < 1;
    }

    if (inventoryTypeProp == 'PACK') {
      return quantityInPacks === null || (quantityInPacks as number) < 1;
    }

    return quantity !== null && (quantity as number) < 1;
  };

  const isOutOfStock = useMemo(checkIsOutOfStock, [inventoryTypeProp, quantityInPieces, quantity, quantityInPacks, trackable]);

  return (
    <MainContainer>
      <CartBadgeContainer
        opacity={isOutOfStock ? '0.7' : '1'}
        onClick={handleAddToCart}
        cursor={isOutOfStock ? 'not-allowed' : 'pointer'}
        id="cart-button"
      >
        <BsCartPlusFill className="cart-badge" />
      </CartBadgeContainer>

      <Link to={`/product/${InventoryUrl?.url}`} id="wrapper">
        <ImgContainer>
          <Image isSquare src={imageUrl} alt={'product'} />
        </ImgContainer>
        <ProductInfo>
          <div className="more-info">
            {inventoryName} <img className="pointer" src={pointer} alt="npmore-info" />
          </div>

          <div className="info-div">
            <ProductText color="#4F4F4F" fontSize={detailsFontSize}>
              {inventoryName}
              {inventoryTypeProp == 'PIECES_AND_PACK' || inventoryTypeProp == 'PACK' ? (
                <Span color={primaryGrey} fontSize={'0.5em'}>
                  - Pack x {perPack} Pieces
                </Span>
              ) : null}
            </ProductText>

            <div className="price">
              {inventoryTypeProp == 'NON_TRACKABLE' && (
                <>
                  <ProductText color="#181817" fontSize={detailsFontSize}>
                    {showAmount(price)}
                  </ProductText>
                </>
              )}

              {inventoryTypeProp == 'PIECES' || inventoryTypeProp == 'PACK' ? (
                <>
                  <ProductText color="#181817" fontSize={detailsFontSize}>
                    {showAmount(price)}
                  </ProductText>
                </>
              ) : null}

              {inventoryTypeProp == 'PIECES_AND_PACK' && (
                <>
                  <div style={{ margin: 0, padding: 0 }}>
                    <ProductText color="#181817" fontSize={detailsFontSize}>
                      {showAmount(unitPrice)}/{showAmount(packPrice)}
                    </ProductText>
                  </div>
                  {!isOutOfStock ? (
                    quantityInPacks || quantityInPieces ? (
                      <Absolute>
                        <StatusView>pieces and pack</StatusView>
                      </Absolute>
                    ) : null
                  ) : null}
                </>
              )}

              {inventoryTypeProp == 'VARIATION' && (
                <>
                  <div style={{ margin: 0, padding: 0 }}>
                    <ProductText color={priceColor} fontSize={primaryFontSize}>
                      {showAmount(variationMin || 0)} - {showAmount(variationMax || 0)}
                    </ProductText>
                  </div>
                  {!isOutOfStock ? (
                    quantityInPacks || quantityInPieces ? (
                      <Absolute>
                        <StatusView>{Variations.length} variations</StatusView>
                      </Absolute>
                    ) : null
                  ) : null}
                </>
              )}

              {isOutOfStock ? (
                <Absolute>
                  <StatusView color={red} background={lightRed}>
                    <img src={FobiddenIcon} /> Out of stock
                  </StatusView>
                </Absolute>
              ) : null}

              <ProductText color={lightGrey} fontSize={descriptionFontSize} mt={'1em'}>
                <img src={ShopIcon} /> {shopName}
              </ProductText>
              <ProductText color={lightBlue} fontSize={descriptionFontSize}>
                {formatDistance(distance)}
              </ProductText>
            </div>
          </div>
        </ProductInfo>
      </Link>
      {isModalOpen && (
        <ItemTypeModal
          setinventoryType={setinventoryType}
          inventoryType={inventoryType}
          inventoryTypeProp={inventoryTypeProp}
          setModalOpen={setModalOpen}
          addToCart={addDataToCart}
          unitPrice={unitPrice}
          packPrice={packPrice}
          Variations={Variations}
          perPack={perPack || 0}
          inventoryId={inventoryId}
          productName={inventoryName}
          imageurl={imageUrl}
          merchant={shopName || ''}
          inventoryPrice={inventory.InventoryPrice}
        />
      )}
    </MainContainer>
  );
};

export default withRouter(connect(null, { currentShopInventoryIdAction })(ProductCard));
