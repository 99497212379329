import styled from 'styled-components';
import { media, Colors, FontSizes } from '../../../constants/theme';
import { Text } from '../../../GlobalStyles/CustomizableGlobal.style';
import Bg from '../../../img/headerBg.svg';

const { primaryColor, lightGrey, primaryGrey, white, body } = Colors;
const { mobile, tablet } = media;
const { secondaryFontSize } = FontSizes;

interface Props {
  left?: boolean;
  isOnline?: boolean;
}

export const ShopHeaderContainer = styled.header<Props>`
  background-image: url(${Bg});
  background-size: cover;
  background-position: center;

  #phone {
    width: 100%;
  }

  .location-wrapper {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #40484ea3;
  }
  .location {
    position: relative;
    background-color: #fff;
    padding: 3rem;
    border-radius: 8px;

    img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-color: #7a8a9610;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;
      transition: all ease-in-out 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .text {
    margin: 0;
    color: ${lightGrey};

    ${tablet} {
      font-size: 12px;
    }

    ${mobile} {
      font-size: 10px;
    }
  }

  .status {
    color: ${({ isOnline }) => (isOnline ? primaryColor : '#C4C4C4')};
  }
`;

export const ShopBannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  width: calc(100% - 64px);
  padding: 1rem 2rem;
  border-bottom: 1px solid #e4ebf4;
  z-index: 6;
  transition: all ease-in-out 0.3s;

  ${tablet} {
    height: 100px;
    padding: 0.4rem 1rem;
    width: calc(100% - 32px);
  }
  ${mobile} {
    height: 70px;
    padding: 0.3rem 1rem;
    // width: calc(100% - 32px);
    margin-top: 50px;
  }
`;

export const LeftContent = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 100%;
  min-height: 50%;

  #phone {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    cursor: pointer;
  }

  .phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 2px 0;
    color: ${primaryGrey};
    text-decoration: none;
    font-weight: 700;
  }

  .text {
    color: ${primaryGrey};
  }

  #status-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-color: ${({ isOnline }) => (isOnline ? '#6CFF7C' : '#C4C4C4')};
  }

  .online {
    color: #6cff7c;
  }

  .details {
    display: flex;
    gap: 10px;
    max-height: 100%;
    height: 100%;
    min-height: 40%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 2rem;
  }
  .details > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .details > div > img {
    margin-right: 20px;
    width: 20px;
  }

  .shop_name {
    color: ${body};
    font-size: 22px;
    font-weight: 700;
    :hover {
      opacity: 0.7;
      color: ${primaryColor};
    }
  }

  ${tablet} {
    .details {
      margin-left: 2rem;
      height: 85%;
    }

    .text {
      font-size: 12px;
    }
    .shop_name {
      font-size: 14px;
      font-weight: 600;
    }

    .details > div > img {
      margin-right: 10px;
      width: 15px;
    }
  }

  ${mobile} {
    align-items: center;

    .details {
      gap: 3px;
      height: 100%;
      margin-left: 0.4rem;
    }
    #status-icon {
      width: 8px;
      height: 8px;
    }

    .text {
      font-size: 8px;
    }
    .shop_name {
      font-size: 10px;
      font-weight: 600;
    }
    .details > div > img {
      align-items: flex-start;
      margin-right: 10px;
      width: 10px;
    }
  }
`;

export const RightContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 5;

  #search-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 8;
  }

  .menu {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
  }
  .distance {
    text-align: right;
    font-size: 16px;
  }

  .remove-popup {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -8;
  }

  .contact-container {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 1rem 0;

    &:hover {
      .chat {
        display: flex;
        bottom: -100px;
        right: 0;
      }
    }

    .contact-button {
      width: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0.4em 1rem;
      border: 1px solid ${primaryColor};
      border-radius: 2rem;
      background-color: ${white};
      color: ${primaryColor};
      font-weight: 700;
      cursor: pointer;
      align-self: end;
      min-height: 40px;
      height: 40px;

      .caret-icon {
        margin-left: 10px;
        color: ${primaryColor};
      }

      &:hover {
        background-color: ${primaryColor};
        color: ${white};

        .caret-icon {
          color: ${white};
        }
      }
    }

    .chat {
      display: none;
      border-radius: 10px;
      width: 300px;
      flex-direction: column;
      gap: 5px;
      align-items: left;
      margin: 10px 0 0 0;
      background: white;
      z-index: 100;
      box-shadow: 4px 4px 30px rgba(35, 54, 79, 0.1);
    }
  }

  ${tablet} {
    height: 100%;

    .contact-container {
      position: absolute;
      bottom: 5px;
      margin: 0;

      .contact-button {
        position: absolute;
        bottom: 0;
        padding: 0.4em 0.7rem;
        width: 130px;
        min-height: 30px;
        height: 30px;
        font-size: 10px;
      }
    }

    .distance {
      font-size: 14px;
    }
  }

  ${mobile} {
    height: 100%;

    .contact-container > .contact-button {
      padding: 3px 5px;
      font-size: 8px;
      width: 100px;
      min-height: 30px;
      height: 30px;
    }

    .menu {
      bottom: -500px;
    }
    .distance {
      font-size: 8px;
      padding: 0;
      margin: 0;
    }
    .caret-icon {
      margin-left: 5px;
    }
    .contact-button {
      width: 90px;
      padding: 0px;
      font-size: 8px;
      font-weight: 500;
      min-height: 20px;
      height: 20px;
    }
  }
`;
export const Container = styled.div<Props>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .status {
    position: absolute;
    color: ${white};
    font-weight: 600;
    border-radius: 20px;
    padding: 0.3rem 1.5rem;
    bottom: 0px;
    background: ${({ isOnline }) => (isOnline ? '#6CFF7C' : '#C4C4C4')};
  }
  p {
    margin: 0;
    font-size: 18px;
    text-transform: uppercase;
  }
  ${tablet} {
    .status {
      padding: 0.1rem 0.8rem;
    }
    p {
      font-size: 14px;
    }
  }
  ${mobile} {
    .status {
      border-radius: 18px;
      padding: 2px 5px;
    }
    p {
      font-size: 10px;
    }
  }
`;

export const ShopName = styled(Text)`
  width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ShopImageContainer = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 10%;
  overflow: hidden;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;

  ${tablet} {
    width: 80px;
    height: 80px;
  }
  ${mobile} {
    width: 50px;
    height: 50px;
  }
`;

export const ShopInfoBanner = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${tablet} {
    top: 8em;
  }
  ${mobile} {
    align-items: center;
  }
`;

export const ShopImage = styled.img`
  justify-content: center;
  align-items: center;
  min-width: 140px;
  object-fit: contain;
  flex-shrink: 0;

  ${tablet} {
    width: 100px;
    min-width: 80px;
  }
`;

export const MessageBox = styled.div`
  width: 100%;

  i {
    font-size: ${secondaryFontSize};
  }
`;
export const ProductChatContainer = styled.div`
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 10px;
`;
export const ChatProductCard = styled.div`
  position: relative;
  display: flex;
  background: #f6f8fb;
  height: 55px;
  border-radius: 4px;
  overflow: hidden;
  h4 {
    font-size: 10px;
    font-weight: 400;
    color: #4f4f4f;
    margin: 1px;
    padding-bottom: 5px;
  }
  #product-name {
    font-size: 10px;
    color: #8196b3;
    margin: 1px;
    span {
      font-weight: 700;
    }
  }
  div:first-of-type {
    width: calc(100% - 55px);
    padding: 6px 12px;
  }
  div:last-of-type {
    width: 55px;
  }
  #img {
    position: relative;
    height: 45px;
    max-height: 45px;
    width: auto;
    background: #f0f0f0;
    padding: 4px;
    top: 0;
    right: 0;
  }
`;
