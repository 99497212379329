import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Row, TopNavLink } from './topNav.style';
import { addToCartFromLocalStorage } from '../../../store/actions/cartCountAction';
import { getItem } from '../../../utils/localStorage.utils';
import history from '../../../utils/history.utils';
import ComingSoonModal from '../../ComingSoonModal';
import { isloading, resetFetch } from '../../../store/actions/fetchAction';
import formatImageUrl from '../../../utils/format.utils';
import { Flex, Span } from '../../../GlobalStyles/CustomizableGlobal.style';
import { GET_INVENTORIES_TYPE } from '../../../interfaces/inventory.interface';
import { GET_INVENTORIES } from '../../../schema/inventory.schema';

interface Props {
  addToCartFromLocalStorage: Function;
}

const TopNav: FunctionComponent<Props> = ({ addToCartFromLocalStorage }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [inventoryIds, setInventoryIds] = useState([]);

  const { data, loading } = useQuery<GET_INVENTORIES_TYPE>(GET_INVENTORIES, {
    variables: {
      inventoryIds: inventoryIds
    }
  });

  useEffect(() => {
    if (getItem('allCartItems')) {
      let allCartItems = JSON.parse(getItem('allCartItems') || '[]');
      const localInventoryIds = allCartItems.map((item: any) => item.id);
      setInventoryIds(localInventoryIds);
    }
  }, []);

  useEffect(() => {
    let allCartItems = JSON.parse(getItem('allCartItems') || '[]');
    dispatch(isloading());
    if (data) {
      let updatedCartItems = allCartItems.map((item: any, index: number) => {
        const itemData = data?.getInventories?.[index];
        const inventoryItems = allCartItems.filter((item: any) => item.id === itemData?.inventoryId);
        const editedItems = inventoryItems.map((currentItem: any, index: number) => {
          let itemCopy = {
            ...currentItem,
            title: itemData?.inventoryName,
            image: formatImageUrl(itemData?.Images?.[0]?.mediumImageOnlineURL)
          };
          let currentVariation = itemData?.Variations?.filter((variation) => variation.variationId === currentItem.variationId)[0];
          switch (currentItem?.inventoryType) {
            case 'PIECES':
              itemCopy = { ...itemCopy, price: itemData?.TrackableItem?.unitPrice };
              break;
            case 'PACK':
              itemCopy = { ...itemCopy, price: itemData?.TrackableItem?.packPrice };
              break;
            case 'NON_TRACKABLE':
              itemCopy = { ...itemCopy, price: itemData?.NonTrackableItem?.sellingPrice };
              break;
            case 'VARIATION':
              itemCopy = { ...itemCopy, price: currentVariation?.price, variationName: currentVariation?.variationName };
              break;
          }
          return itemCopy;
        });
        return editedItems;
      });
      const merged = [].concat.apply([], updatedCartItems);
      addToCartFromLocalStorage(merged);
      dispatch(resetFetch());
    }
  }, [data]);

  const showAlert = (): void => {
    history.push(`${location.pathname}${location.search ? `/${location.search}&` : '?'}modal=download`);
  };

  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQueryParams();
  const modal = query.get('modal');

  return (
    <>
      <Row>
        <div></div>
        <Flex background="transparent">
          {/* <a
            className="download-app"
            onClick={showAlert}
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_DOWNLOAD_SITE_LINK}
          >
            Download App
          </a> */}
          <Link to="/contact-us" className="text-none">
            <TopNavLink>Contact Us</TopNavLink>
          </Link>
        </Flex>
      </Row>
      {modal == 'download' && <ComingSoonModal />}
    </>
  );
};

export default withRouter(connect(null, { addToCartFromLocalStorage })(TopNav));
